var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import mediaQueries from "../../styles/media-queries";
import styled from "../../styles/styled";
import { Badge, PlayerStyle } from "../Player/style";
var SubstitutesStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: ", ";\n    max-width: 320px;\n    padding: 5% 5%;\n\n    @media ", " {\n        max-width: 575px;\n    }\n\n    @media ", " {\n        max-width: 100%;\n    }\n\n    .substitutes {\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: center;\n        width: 100%;\n\n        ", " {\n            margin: 20px;\n\n            ", " {\n                h4 {\n                    color: black !important;\n                }\t\t\t\t\n            }\n        }\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: ", ";\n    max-width: 320px;\n    padding: 5% 5%;\n\n    @media ", " {\n        max-width: 575px;\n    }\n\n    @media ", " {\n        max-width: 100%;\n    }\n\n    .substitutes {\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: center;\n        width: 100%;\n\n        ", " {\n            margin: 20px;\n\n            ", " {\n                h4 {\n                    color: black !important;\n                }\t\t\t\t\n            }\n        }\n    }\n"])), function (props) { return props.bgColor; }, mediaQueries.mobileM, mediaQueries.tablet, PlayerStyle, Badge);
var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: #FFF;\n    margin-bottom: 20px;\n"], ["\n    color: #FFF;\n    margin-bottom: 20px;\n"])));
export { SubstitutesStyle, Title };
var templateObject_1, templateObject_2;
