var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Player, { PlayerType } from '../Player';
import { SubstitutesStyle, Title } from './style';
var Substitutes = /** @class */ (function (_super) {
    __extends(Substitutes, _super);
    function Substitutes() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Substitutes.prototype.render = function () {
        var _a = this.props, title = _a.title, bgColor = _a.bgColor, selection = _a.selection, playerType = _a.playerType, playerBadgeColor = _a.playerBadgeColor, playerBadgeBgColor = _a.playerBadgeBgColor, playerPointsColor = _a.playerPointsColor, playerPointsBgColor = _a.playerPointsBgColor, onRemove = _a.onRemove, onSwap = _a.onSwap, swapPlayerId = _a.swapPlayerId, swappedFrom = _a.swappedFrom, assetsCdn = _a.assetsCdn, onPlaceholderClick = _a.onPlaceholderClick, actionLessPlayerIds = _a.actionLessPlayerIds, showPlayerValue = _a.showPlayerValue, showStatsPoints = _a.showStatsPoints, subTitle = _a.subTitle;
        return (React.createElement(SubstitutesStyle, { bgColor: bgColor },
            React.createElement(Title, null, title),
            subTitle ? React.createElement("p", null,
                " ",
                subTitle,
                " ") : null,
            React.createElement("div", { className: "substitutes" }, selection.map(function (player, index) {
                var sportSpecificProps = {};
                if (playerType === PlayerType.Cycling && player) {
                    sportSpecificProps.shirtCycling = assetsCdn + "/jerseys/cycling/" + player.clubId + ".png";
                }
                // if (showStatsPoints && player.stats && player.stats.length) {
                //     player.points = player.stats.reduce((acc: any, item: any) => acc + item.points, 0);
                // }
                return (React.createElement(Player, __assign({ key: "substitutes-" + index, pointsColor: playerPointsColor, pointsBgColor: playerPointsBgColor, badgeColor: playerBadgeColor, badgeBgColor: playerBadgeBgColor, player: player, type: playerType, showPlayerValue: showPlayerValue, onRemove: onRemove, onSwap: onSwap, swapPlayerId: swapPlayerId, swappedFrom: swappedFrom, benchPlayer: true, onPlaceholderClick: onPlaceholderClick, actionLessPlayerIds: actionLessPlayerIds }, sportSpecificProps)));
            }))));
    };
    return Substitutes;
}(Component));
export default Substitutes;
