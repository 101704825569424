import { Config, Environment } from '@fanarena/shared';

interface SporzaEnvironment extends Environment {
    REACT_APP_VRT?: string;
}

const {
    REACT_APP_VRT,
} = process.env as SporzaEnvironment

const config = {
    ...Config,
    VRT: JSON.parse(REACT_APP_VRT ?? '{}')
};

export default config;
