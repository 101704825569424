import { Redux } from '@fanarena/shared';
import lockr from 'lockr';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import config from '../../config';

const ScriptID = 'VrtSSOScript';
const VrtConfigValid =
    Object.keys(config.VRT).indexOf('clientId') > -1 &&
    Object.keys(config.VRT).indexOf('ssoPath') > -1 &&
    Object.keys(config.VRT).indexOf('scriptSrc') > -1;

interface VrtLoginProps {
    id: string;
    className?: string;
    style?: any;
    elemStyle?: any;
    theme: 'c1' | 'c2' | 'c3' | 'c4';
    fetchUserTeams: any;
    logout: any;
}

function VrtLogin({ id, application, logout, fetchUserTeams, ...props }: VrtLoginProps & WithStateToProps) {
    const [lastSsoEventState, setLastSsoEventState] = React.useState<number>();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onVrtStateChanged = React.useCallback(
        debounce((event: any) => {
            const widgetUser = window.VRT.SSOController.getUserData();
            const marketingId = window.VRT.SSOController.getMarketingId();

            if (!widgetUser) {
                lockr.rm('token');
                lockr.rm('user');
                logout();
                return;
            }

            const user = {
                id: widgetUser.email,
                firstName: widgetUser.given_name,
                lastName: widgetUser.family_name,
                email: widgetUser.email,
            };

            lockr.set('marketingId', marketingId);
            lockr.set('token', widgetUser.id_token);
            lockr.set('widgetUser', widgetUser);

            if (event.detail.state === 1 && lastSsoEventState !== 1) {
                fetchUserTeams(application.competition.competitionFeed);
            }

            setLastSsoEventState(event.detail.state);

            if (user && user.email && user.email.length && (window as any).$crisp && (window as any).$crisp.push) {
                (window as any).$crisp.push(['set', 'user:email', user.email]);
            }
        }, 50),
        [application, logout, fetchUserTeams, setLastSsoEventState, lastSsoEventState],
    );

    React.useEffect(() => {
        const existing = document.getElementById(ScriptID);
        if (existing || VrtConfigValid === false) return;

        if (!(window.VRT && window.VRT.SSOConfig)) {
            window.VRT = window.VRT || {};
            window.VRT.SSOConfig = window.VRT.SSOConfig || {
                clientId: config.VRT.clientId,
                ssoPath: config.VRT.ssoPath,
            };
        }

        const script = document.createElement('script');

        script.id = ScriptID;
        script.src = config.VRT.scriptSrc;
        script.async = true;
        script.onload = () => {
            window.VRT.SSOController.addEventListener('SSO-stateChanged', onVrtStateChanged);
        };

        document.body.appendChild(script);
    }, [onVrtStateChanged]);

    if (VrtConfigValid === false) return null;

    return (
        <div className={props.className} style={props.style}>
            <sso-login id={id} style={props.elemStyle} theme={props.theme}></sso-login>
        </div>
    );
}

const mapDispatchToProps = {
    fetchUserTeams: Redux.Actions.User.fetchTeams,
    logout: Redux.Actions.User.logOutUser,
};

function mapStateToProps({ user, application, matches }: Redux.StoreState) {
    return {
        user,
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VrtLogin);
