var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'antd/lib/spin/style';
import 'antd/lib/icon/style';
import { Icon } from 'antd';
import styled from '../../../styles/styled';
export var SpinnerIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 24px;\n"], ["\n    font-size: 24px;\n"])));
var templateObject_1;
