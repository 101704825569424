import {
    AbstractTeam,
    Block,
    Col,
    Models,
    Redux,
    Row,
    TableStyle,    Title,
    TitleType } from '@fanarena/shared';
import React, { Component } from 'react';

export interface Props {
    match: any;
}

export interface State {
    notFound: boolean;
    loaded: boolean;
    teams: any;
}

class TeamContainer extends Component<Models.AbstractTeamType & Props, State> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);

        this.state = {
            notFound: false,
            loaded: false,
            teams: null,
        };
    }

    componentDidMount() {
        // Redux.Actions.User.fetchHistory('KLAS').then((result: any) => {
        //     console.log(result);
        //     this.setState({ teams: result.teams, loaded: true });
        // });
    }

    render() {
        const columns = [
            {
                title: 'Game',
                key: 'game',
                dataIndex: 'record.id',
                width: '10%',
                render: (text: string, record: any) => {
                    return (
                        <p>Klassiekers 2023</p>
                    );
                },
            },
            {
                title: 'Team',
                key: 'teamName',
                dataIndex: 'teamName',
                width: '50%',
                render: (text: string, record: any) => {
                    return (
                        <p>{record.name}</p>
                    );
                },
            },
            {
                key: 'points',
                title: 'Punten',
                width: '15%',
                dataIndex: 'points',
                render: (text: string, record: any) => {
                    return <span style={{ float: 'right' }}><b>{record.points.toLocaleString('fr-BE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b></span>;
                },
            },
            {
                key: 'date',
                title: 'Resultaat',
                width: '15%',
                dataIndex: 'date',
                render: (text: string, record: any) => {
                    return <span style={{ float: 'right' }}><b>{record.rank.toLocaleString('fr-BE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</b>/65948</span>;
                },
            },
        ];
    
        return this.state.loaded ? (
            <Row>
                <Col span={24}>
                    <Block>
                        <Title type={TitleType.h3}>Team Geschiedenis</Title>
                        <TableStyle
                            columns={columns}
                            dataSource={this.state.teams}
                            showHeader={true}
                            locale={{ emptyText: 'No matches found.' }}
                            loading={!this.state.loaded}
                            pagination={this.state.teams?.length > 10 ? { pageSize: 10 } : false}
                            rowKey="id"
                            rowClassName={(_record: any, index: number) =>
                                index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
                            }
                        />
                    </Block>
                </Col>
            </Row>
        ) : null;
    }
}

const WrappedComponent = AbstractTeam(TeamContainer);

export default WrappedComponent;
