import { Redux } from '@fanarena/shared';
import { Row } from 'antd';
import lockr from 'lockr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

export interface Props {
    location: any;
    match: any;
}

class Join extends Component<Props & WithStateToProps> {
    state = {
        redirectToHome: false,
        redirectToLeagues: false,
    };

    componentDidMount() {
        if (this.props.match.params.code) {
            lockr.set('inviteCode', this.props.match.params.code);
            if (this.props.user.authenticated) {
                this.setState({ redirectToLeagues: true });
            } else {
                this.setState({ redirectToHome: true });
            }
        } else {
            this.setState({ redirectToHome: true });
        }
    }

    render() {
        return (
            <Row>
                {this.state.redirectToHome && <Redirect to={{ pathname: `/home` }} />}

                {this.state.redirectToLeagues && <Redirect to={{ pathname: `/leagues` }} />}
            </Row>
        );
    }
}

export const mapDispatchToProps = {};

function mapStateToProps({ user, application }: Redux.StoreState) {
    return {
        user,
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Join);
