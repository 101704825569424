var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { PointStyle, RowStyle, StatsStyle } from './style';
var Stats = /** @class */ (function (_super) {
    __extends(Stats, _super);
    function Stats() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Stats.prototype.render = function () {
        return (React.createElement(StatsStyle, null,
            React.createElement(RowStyle, null,
                React.createElement(PointStyle, null,
                    "Punten speeldag",
                    React.createElement("span", null, this.props.visibleWeekPoints)),
                React.createElement(PointStyle, null,
                    "Punten totaal",
                    React.createElement("span", null, this.props.generalPoints))),
            React.createElement(RowStyle, null,
                React.createElement(PointStyle, null,
                    React.createElement("span", null, this.props.visibleWeekRank),
                    "Plaats speeldag"),
                React.createElement(PointStyle, null,
                    React.createElement("span", null, this.props.generalRank),
                    "Plaats algemeen"))));
    };
    return Stats;
}(Component));
export default Stats;
