var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useLockBodyScroll } from '@fransvilhelm/hooks';
import { Icon } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import bg from '../../../assets/img/header/bg.jpg';
import * as applicationActions from '../../redux/actions/application';
import * as clubsActions from '../../redux/actions/clubs';
import * as gameDataActions from '../../redux/actions/data';
import * as matchesActions from '../../redux/actions/matches';
import * as playersActions from '../../redux/actions/players';
import * as userActions from '../../redux/actions/user';
import Layout from '../UI/Layout';
import { HeaderBackground, HeaderStyle, NavLastItem } from './style';
function MobileLockScroll() {
    useLockBodyScroll();
    return null;
}
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.headerRef = React.createRef();
        _this.state = {
            windowWidth: window.innerWidth,
            gameSwitcherOpen: false,
        };
        _this._checkCurrentPageTitle = function () {
            // console.log("state switch")
            if (window && window.digitalData) {
                window.digitalData.page.page_url = document.location.href.split('?')[0];
                window.digitalData.page.page_uri = document.location.pathname;
                window.digitalData.page.trackPageView = "true";
                window.digitalData.page.competition_name = _this.props.application.competition.competitionFeed;
                // page view event
                // event naming: custom event = wielermanager_pageview
                // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
                var page = new CustomEvent("wielermanager_pageview", {
                    detail: {
                        name: window.digitalData.page.page_uri,
                    },
                });
                // console.log(`wielermanager_pageview ${window.digitalData.page.page_uri}`);
                document.dispatchEvent(page);
            }
            if (window && window.adhese) {
                window.adhese.ClearAds();
                window.adhese.GetAds(false);
            }
            // if (staticPagesTitleMap[this.props.location.pathname]) {
            // 	this.setState({ pageTitle: staticPagesTitleMap[this.props.location.pathname] });
            // } else if (this.props.location.pathname.includes('my-teams/team/')) {
            // 	const teamId = this.props.location.pathname
            // 		.split('/')
            // 		.find((chunk: any) => !!parseInt(chunk));
            // 	const team = teamId && this.props.user.teams.find((team: any) => team.id === parseInt(teamId));
            // 	if (team) {
            // 		this.setState({ pageTitle: team.name });
            // 	}
            // } else {
            // 	this.setState({ pageTitle: '' });
            // }
        };
        _this.onWindowResize = function () {
            _this.setState({ windowWidth: window.innerWidth });
        };
        _this.onMenuItemNavigate = function () {
            _this.setState({ gameSwitcherOpen: false });
        };
        _this._handleCompetitionFeedSelect = function (competitionFeed) {
            _this.setState({ gameSwitcherOpen: false });
            _this.props.switchCompetitionFeed(competitionFeed);
            // this.props.history.go(0);
        };
        _this._competitionFeedDescription = function (competitionFeed) {
            switch (competitionFeed) {
                case 'GIRO':
                    return 'Giro d\'Italia';
                // case 'KLASF':
                //     return 'klassiekers vrouwen';
                // case 'KLASM':
                //     return 'klassiekers mannen';
            }
        };
        _this.onLogOut = function () {
            _this.props.logout();
        };
        return _this;
    }
    Header.prototype.componentDidMount = function () {
        this.props.fetchGameData(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
        // this.props.fetchPlayers(
        //     this.props.application.competition.competitionFeed,
        //     this.props.application.competition.seasonId,
        // );
        // this.props.fetchClubs(
        //     this.props.application.competition.competitionFeed,
        //     this.props.application.competition.seasonId,
        // );
        this.props.fetchMatchesInfo(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
        this.props.fetchMatches(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
        if (this.props.user.data.id) {
            console.log("ONCE");
            // this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
        }
        this._checkCurrentPageTitle();
        window.addEventListener('resize', this.onWindowResize);
    };
    Header.prototype.componentDidUpdate = function (prevProps) {
        if (this.state.gameSwitcherOpen) {
            document.body.classList.add('lock-scroll');
        }
        else {
            document.body.classList.remove('lock-scroll');
        }
        if (this.props.location !== prevProps.location) {
            this._checkCurrentPageTitle();
        }
        if (this.props.user.teams.length !== prevProps.user.teams.length && this.props.user.teams.length) {
            var team = this.props.user.teams[0];
            this.props.fetchTeamLeagues(team.id);
        }
        if (this.props.application.activeFeed !== prevProps.application.activeFeed) {
            this.props.fetchUserTeamsAndLeagues(this.props.application.competition.competitionFeed);
            this.props.fetchGameData(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
            this.props.fetchMatchesInfo(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
            this.props.fetchMatches(this.props.application.competition.competitionFeed, this.props.application.competition.seasonId);
        }
    };
    Header.prototype.render = function () {
        var _this = this;
        var _a = this.props, matches = _a.matches, user = _a.user, location = _a.location, application = _a.application, renderVrtWidget = _a.renderVrtWidget;
        var userTeam = user.teams[0];
        var showPoints = userTeam && matches.info.deadlineWeek && matches.info.deadlineWeek > userTeam.weekId;
        var showTransfers = userTeam &&
            matches.info.deadlineWeek &&
            matches.info.deadlineWeek > userTeam.weekId &&
            matches.info.deadlineWeek > application.competition.competitionStartingWeek;
        var isActive = function (match) { return location.pathname.indexOf(match) !== -1; };
        var gameOver = !matches.info.deadlineWeek;
        var gameInProgress = !!matches.info.deadlineWeek;
        var allMenuItems = ['home', 'rankings', 'stats', 'rules'];
        var menuTotalItems = 4;
        if (user.authenticated) {
            allMenuItems.push('history');
            menuTotalItems += 1;
        }
        if (user.authenticated && userTeam) {
            allMenuItems.push('leagues');
            menuTotalItems += 1;
        }
        if (this.props.application.competition.competitionFeed !== 'KLASF') {
            allMenuItems.push('prizes');
            menuTotalItems += 1;
        }
        var visibleMenuItems = [].concat(allMenuItems);
        // let collapsedMenuItems: string[] = [];
        var menuItemSize = 100;
        var insertToMenuAtPosition = function (positionIndex, item) {
            if (allMenuItems.indexOf(item) === -1) {
                allMenuItems.splice(positionIndex, 0, item);
                visibleMenuItems = [].concat(allMenuItems);
                menuTotalItems += 1;
            }
        };
        if ((user.authenticated && showPoints) || gameOver) {
            insertToMenuAtPosition(1, 'points');
        }
        else if (user.authenticated && !userTeam) {
            insertToMenuAtPosition(1, 'new');
        }
        if (user.authenticated && userTeam && gameInProgress) {
            if (showPoints) {
                insertToMenuAtPosition(2, 'team');
            }
            else {
                insertToMenuAtPosition(1, 'team');
            }
        }
        if (user.authenticated && userTeam && !showTransfers && gameInProgress) {
            if (showPoints) {
                insertToMenuAtPosition(3, 'edit');
            }
            else {
                insertToMenuAtPosition(2, 'edit');
            }
        }
        if (user.authenticated && showTransfers && gameInProgress) {
            if (showPoints) {
                insertToMenuAtPosition(4, 'transfers');
            }
            else {
                insertToMenuAtPosition(3, 'transfers');
            }
        }
        // if (this.state.windowWidth > 768 && this.state.windowWidth < 810) {
        //     const containerWidth = this.headerRef.current
        //         ? this.headerRef.current.offsetWidth
        //         : this.state.windowWidth - (this.state.windowWidth * 10) / 100;
        //     const availableMenuSpace = containerWidth - 150;
        //     const result = Math.floor(availableMenuSpace / menuItemSize);
        //     const rank = result < menuTotalItems ? result - 1 : result;
        //     visibleMenuItems = allMenuItems.slice(0, rank);
        //     // collapsedMenuItems = allMenuItems.slice(rank, menuTotalItems);
        // }
        var isVisible = function (menuItem) { return visibleMenuItems.indexOf(menuItem) !== -1; };
        return (React.createElement(React.Fragment, null, !this.props.application.competition.inactive && (React.createElement(HeaderBackground, { bg: bg },
            React.createElement(Layout, { style: { background: 'transparent' } },
                React.createElement(HeaderStyle, { ref: this.headerRef },
                    React.createElement("div", { className: "header-section" },
                        React.createElement("div", { className: "hamburger-menu" },
                            React.createElement("button", { onClick: function () { return _this.setState({ gameSwitcherOpen: !_this.state.gameSwitcherOpen }); }, style: { padding: '0px' } },
                                React.createElement("span", { style: { paddingRight: '10px' } }, this.state.gameSwitcherOpen
                                    ? React.createElement(Icon, { "aria-hidden": "true", type: "close" })
                                    : '☰'),
                                React.createElement("span", { className: "active-feed" }, this._competitionFeedDescription(application.activeFeed))),
                            this.state.gameSwitcherOpen && (React.createElement("div", { className: "game-switcher" },
                                React.createElement("nav", { className: "mobile-nav-links" },
                                    React.createElement(Link, { className: "nav-link " + (isActive('home') || location.pathname === '/' ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/home" }, "Home"),
                                    (userTeam && isVisible('points') && (React.createElement(Link, { className: "nav-link" + (isActive('points') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/points/" + userTeam.id }, "Punten"))) || null,
                                    (isVisible('team') && (React.createElement(Link, { className: "nav-link " + (isActive('team') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/team/" + userTeam.id }, "Opstelling"))) ||
                                        null,
                                    (isVisible('edit') && (React.createElement(Link, { className: "nav-link " + (isActive('edit') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/edit/" + userTeam.id }, "Selectie"))) ||
                                        null,
                                    (isVisible('new') && (React.createElement(Link, { className: "nav-link " + (isActive('new') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/new" }, "Selectie"))) ||
                                        null,
                                    (isVisible('transfers') && (React.createElement(Link, { className: "nav-link " + (isActive('transfers') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/transfers/" + userTeam.id }, "Transfers"))) ||
                                        null,
                                    (isVisible('leagues') && (React.createElement(Link, { className: "nav-link " + (isActive('leagues') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/leagues" }, "Minicompetitie"))) ||
                                        null,
                                    (isVisible('rankings') && (React.createElement(Link, { className: "nav-link " + (isActive('rankings') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/rankings" }, "Rangschikking"))) ||
                                        null,
                                    (isVisible('stats') && (React.createElement(Link, { className: "nav-link " + (isActive('stats') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/stats" }, "Stats"))) ||
                                        null,
                                    (isVisible('rules') && (React.createElement(Link, { className: "nav-link " + (isActive('rules') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/rules" }, "Spelregels"))) ||
                                        null,
                                    (isVisible('prizes') && (React.createElement(Link, { className: "nav-link " + (isActive('prizes') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/prizes" }, "Prijzen"))) ||
                                        null),
                                React.createElement("nav", { style: {
                                        background: '#2E2F2D',
                                        borderRadius: '5px',
                                        border: '1px solid #2E2F2D',
                                        padding: '5px',
                                        marginBottom: '10px'
                                    } }, application.feeds.map(function (feed) { return (React.createElement("button", { style: {
                                        padding: '10px'
                                    }, key: feed, className: application.activeFeed === feed ? 'active' : '', onClick: function () { return _this._handleCompetitionFeedSelect(feed); } },
                                    application.activeFeed === feed ? React.createElement(Icon, { type: "caret-right", theme: "filled" }) : '',
                                    " ",
                                    _this._competitionFeedDescription(feed))); })))))),
                    React.createElement("div", { className: "header-section" },
                        React.createElement("nav", { className: "nav-links" },
                            React.createElement(Link, { className: "nav-link " + (isActive('home') || location.pathname === '/' ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/home" }, "Home"),
                            (userTeam && isVisible('points') && (React.createElement(Link, { className: "nav-link" + (isActive('points') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/points/" + userTeam.id }, "Punten"))) || null,
                            (isVisible('team') && (React.createElement(Link, { className: "nav-link " + (isActive('team') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/team/" + userTeam.id }, "Opstelling"))) ||
                                null,
                            (isVisible('edit') && (React.createElement(Link, { className: "nav-link " + (isActive('edit') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/edit/" + userTeam.id }, "Selectie"))) ||
                                null,
                            (isVisible('new') && (React.createElement(Link, { className: "nav-link " + (isActive('new') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/new" }, "Selectie"))) ||
                                null,
                            (isVisible('transfers') && (React.createElement(Link, { className: "nav-link " + (isActive('transfers') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/transfers/" + userTeam.id }, "Transfers"))) ||
                                null,
                            (isVisible('leagues') && (React.createElement(Link, { className: "nav-link " + (isActive('leagues') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/leagues" }, "Minicompetitie"))) ||
                                null,
                            (isVisible('rankings') && (React.createElement(Link, { className: "nav-link " + (isActive('rankings') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/rankings" }, "Rangschikking"))) ||
                                null,
                            (isVisible('stats') && (React.createElement(Link, { className: "nav-link " + (isActive('stats') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/stats" }, "Stats"))) ||
                                null,
                            (isVisible('rules') && (React.createElement(Link, { className: "nav-link " + (isActive('rules') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/rules" }, "Spelregels"))) ||
                                null,
                            (isVisible('prizes') && (React.createElement(Link, { className: "nav-link " + (isActive('prizes') ? ' active' : ''), onClick: this.onMenuItemNavigate, to: "/prizes" }, "Prijzen"))) ||
                                null)),
                    React.createElement("div", { className: "header-section" }, renderVrtWidget ? React.createElement(NavLastItem, { style: { '--SSObtn_hover': "#FF286E" } }, renderVrtWidget()) : null)))))));
    };
    return Header;
}(Component));
export var mapDispatchToProps = {
    switchCompetitionFeed: applicationActions.SwitchCompetitionFeed,
    fetchGameData: gameDataActions.FetchGameData,
    fetchPlayers: playersActions.FetchPlayers,
    fetchClubs: clubsActions.FetchClubs,
    fetchMatchesInfo: matchesActions.FetchMatchesInfo,
    fetchMatches: matchesActions.FetchMatches,
    fetchUserTeamsAndLeagues: userActions.FetchTeams,
    fetchTeamLeagues: userActions.FetchUserTeamLeaguesAndPersist,
    logout: userActions.LogOutUser,
    onSetProfileData: function (data) { return function (dispatch) { return dispatch(userActions.SetProfileData(data)); }; },
};
export function mapStateToProps(_a) {
    var user = _a.user, application = _a.application, matches = _a.matches;
    return {
        user: user,
        application: application,
        matches: matches
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
