var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Icon from 'antd/es/icon';
import React, { Component } from 'react';
import { FirstLetterUppercased } from './../../lib/helpers';
import { Badge, NoPlayer, PlayerBg, PlayerStyle, Points, SoccerJersey, TopRightAction, Value } from './style';
export var PlayerType;
(function (PlayerType) {
    PlayerType["Cycling"] = "Cycling";
    PlayerType["SoccerShirt"] = "SoccerShirt";
    PlayerType["SoccerPortrait"] = "SoccerPortrait";
})(PlayerType || (PlayerType = {}));
var Player = /** @class */ (function (_super) {
    __extends(Player, _super);
    function Player() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Player.prototype.render = function () {
        var _a = this.props, type = _a.type, player = _a.player, badgeColor = _a.badgeColor, badgeBgColor = _a.badgeBgColor, pointsColor = _a.pointsColor, pointsBgColor = _a.pointsBgColor, shirtCycling = _a.shirtCycling, shirtSoccer = _a.shirtSoccer, portraitFace = _a.portraitFace, soccerJersey = _a.soccerJersey, onRemove = _a.onRemove, onSwap = _a.onSwap, swapPlayerId = _a.swapPlayerId, swappedFrom = _a.swappedFrom, onPlaceholderClick = _a.onPlaceholderClick, actionLessPlayerIds = _a.actionLessPlayerIds, showPlayerValue = _a.showPlayerValue, benchPlayer = _a.benchPlayer;
        var topRightActionIconStyle = {
            fontSize: '12px',
            width: '12px',
            height: '12px',
            color: 'white',
            position: 'absolute',
            left: '4px',
            top: '4px',
            textAlign: 'center',
        };
        var ignoredPlayersIdsForActions = actionLessPlayerIds || [];
        var swappedAlreadyFromPlayerArea = onSwap &&
            swapPlayerId &&
            ((swappedFrom === 'starting' && player.inStarting) || (swappedFrom === 'bench' && !player.inStarting)) &&
            swapPlayerId !== player.id;
        var playerName = player && player.surname + " " + (player.forename && FirstLetterUppercased(player.forename)) + ".";
        var playerHasAction = player && player.id && ignoredPlayersIdsForActions.indexOf(player.id) === -1;
        var pointsEnabled = player && player.points >= 0;
        console.log(player);
        return (React.createElement(PlayerStyle, null,
            player && type === PlayerType.Cycling && (React.createElement(PlayerBg, { "aria-label": "Renner " + playerName, bg: shirtCycling, inactive: swappedAlreadyFromPlayerArea })),
            player && type === PlayerType.SoccerShirt && React.createElement(PlayerBg, { bg: shirtSoccer }),
            player && type === PlayerType.SoccerPortrait && React.createElement(PlayerBg, { bg: portraitFace }),
            player && type === PlayerType.SoccerPortrait && React.createElement(SoccerJersey, { bg: soccerJersey }),
            player && (React.createElement(Badge, { color: badgeColor, bgColor: badgeBgColor, paddingRight: pointsEnabled ? 20 : 0 },
                React.createElement("h4", { "aria-hidden": "true" }, playerName),
                (pointsEnabled && (React.createElement(Points, { "aria-label": "Punten " + playerName, color: pointsColor, bgColor: (player.active === 2) ? '#FFF' : pointsBgColor }, (player.active === 2 ? '🏠' : player.points)))) ||
                    null)),
            (player && showPlayerValue && (React.createElement(Value, { benchPlayer: benchPlayer, style: { margin: '5px' } },
                React.createElement("h4", { "aria-label": "Waarde", style: { color: '#FFF' } },
                    "\u20AC",
                    player.value,
                    "M")))) ||
                null,
            (!player && (React.createElement(NoPlayer, { "aria-hidden": "true", onClick: onPlaceholderClick || (function () { return null; }) },
                React.createElement(Icon, { type: "plus-circle", theme: "filled", style: { fontSize: '2em', color: badgeBgColor, cursor: 'pointer' } })))) ||
                null,
            (player && playerHasAction && onRemove && (React.createElement(TopRightAction, { "aria-label": "Verwijder " + playerName, onClick: function () { return onRemove(player); } },
                React.createElement(Icon, { "aria-hidden": "true", type: "close", style: topRightActionIconStyle })))) ||
                null,
            (player && onSwap && !swappedAlreadyFromPlayerArea && swapPlayerId !== player.id && (React.createElement(TopRightAction, { "aria-label": "Wissel " + playerName, onClick: function () { return onSwap(player); } },
                React.createElement(Icon, { "aria-hidden": "true", type: "swap", style: topRightActionIconStyle })))) ||
                null,
            (player && onSwap && swapPlayerId && swapPlayerId === player.id && (React.createElement(TopRightAction, { "aria-label": "Wissel annuleren " + playerName, onClick: function () { return onSwap(player); } },
                React.createElement(Icon, { "aria-hidden": "true", type: "rollback", style: topRightActionIconStyle })))) ||
                null));
    };
    return Player;
}(Component));
export default Player;
