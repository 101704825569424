import { combineReducers } from 'redux';
import { applicationReducer } from './application';
import { clubsReducer } from './clubs';
import { gameDataReducer } from './data';
import { matchesReducer } from './matches';
import { playersReducer } from './players';
import { userReducer } from './user';
var reducers = combineReducers({
    user: userReducer,
    players: playersReducer,
    application: applicationReducer,
    clubs: clubsReducer,
    matches: matchesReducer,
    gameData: gameDataReducer,
});
export default reducers;
