var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import mediaQueries from "../../styles/media-queries";
import styled from "../../styles/styled";
import { PlayerStyle } from "../Player/style";
var TeamStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    padding: 90px 0 30px;\n    flex: 1;\n    max-width: 575px;\n    height: ", "px; /*  Make mobile size higher */\n    background: url(", ") no-repeat center center/120% 100%;\n\n    .position {\n        display: flex;\n\n        ", " {\n            margin: 0px;\n\n            @media ", " {\n                margin: 0 5px;\n            }\n        }\n    }\n\n    @media ", " {\n        padding: 100px 0 30px;\n        max-width: 575px;\n        height: ", "px;\n    }\n\n    @media ", " {\n        max-width: 100%;\n        height: ", "px;\n        background-size: 100% 100%;\n    }\n"], ["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    padding: 90px 0 30px;\n    flex: 1;\n    max-width: 575px;\n    height: ", "px; /*  Make mobile size higher */\n    background: url(", ") no-repeat center center/120% 100%;\n\n    .position {\n        display: flex;\n\n        ", " {\n            margin: 0px;\n\n            @media ", " {\n                margin: 0 5px;\n            }\n        }\n    }\n\n    @media ", " {\n        padding: 100px 0 30px;\n        max-width: 575px;\n        height: ", "px;\n    }\n\n    @media ", " {\n        max-width: 100%;\n        height: ", "px;\n        background-size: 100% 100%;\n    }\n"])), function (props) { return (props.heightRatio / props.widthRatio) * 1.5 * 320; }, function (props) { return props.bg; }, PlayerStyle, mediaQueries.tablet, mediaQueries.mobileM, function (props) { return (props.heightRatio / props.widthRatio) * 1.5 * 375; }, mediaQueries.tablet, function (props) { return (props.heightRatio / props.widthRatio) * 630; });
var Sponsor = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 75px;\n    background-color: rgba(255,0,0,0.5);\n    background: ", " no-repeat center center/contain;\n    transform: scale(0.8);\n\n    /*\n    @media ", " {\n        top: ", "px;\n        height: ", "px;\n    }\n\n    @media ", " {\n        top: ", "px;\n        height: ", "px;\n    }\n    */\n"], ["\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 75px;\n    background-color: rgba(255,0,0,0.5);\n    background: ", " no-repeat center center/contain;\n    transform: scale(0.8);\n\n    /*\n    @media ", " {\n        top: ", "px;\n        height: ", "px;\n    }\n\n    @media ", " {\n        top: ", "px;\n        height: ", "px;\n    }\n    */\n"])), function (props) { return "url(" + props.bg + ")"; }, mediaQueries.mobileM, function (props) { return (props.heightRatio / props.widthRatio) * 24; }, function (props) { return (props.heightRatio / props.widthRatio) * 18; }, mediaQueries.tablet, function (props) { return (props.heightRatio / props.widthRatio) * 24; }, function (props) { return (props.heightRatio / props.widthRatio) * 19; });
export { TeamStyle, Sponsor };
var templateObject_1, templateObject_2;
