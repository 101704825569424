import { ApplicationActions } from './application';
import { ClubsActions } from './clubs';
import { GameDataActions } from './data';
import { LeaguesActions } from './leagues';
import { MatchesActions } from './matches';
import { PlayersActions } from './players';
import { TeamsActions } from './teams';
import { UserActions } from './user';
export var Actions;
(function (Actions) {
    Actions.Application = ApplicationActions;
    Actions.User = UserActions;
    Actions.Teams = TeamsActions;
    Actions.Matches = MatchesActions;
    Actions.Leagues = LeaguesActions;
    Actions.Clubs = ClubsActions;
    Actions.Players = PlayersActions;
    Actions.GameData = GameDataActions;
})(Actions || (Actions = {}));
