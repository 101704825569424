// import { clubs } from '../../cache';
import http from '../../lib/http';
import { CLUBS_FETCH_FAILED, CLUBS_FETCHED_WITH_SUCCESS, CLUBS_LOADING_STARTED } from './actions-types';
export function LoadClubs() {
    return {
        type: CLUBS_LOADING_STARTED,
    };
}
export function ClubsFetchedWithSuccess(data) {
    return {
        type: CLUBS_FETCHED_WITH_SUCCESS,
        data: data,
    };
}
export function ClubsFetchFailed() {
    return {
        type: CLUBS_FETCH_FAILED,
    };
}
export function FetchClubs(competitionFeed, seasonId) {
    return function (dispatch) {
        dispatch(LoadClubs());
        // dispatch(ClubsFetchedWithSuccess(clubs));
        return http.get("clubs", { competitionFeed: competitionFeed, seasonId: seasonId })
            .then(function (result) {
            dispatch(ClubsFetchedWithSuccess(result.clubs));
        })
            .catch(function (error) {
            console.log(error);
            dispatch(ClubsFetchFailed());
        });
    };
}
export var ClubsActions;
(function (ClubsActions) {
    ClubsActions.loadClubs = LoadClubs;
    ClubsActions.clubsFetchedWithSuccess = ClubsFetchedWithSuccess;
    ClubsActions.clubsFetchFailed = ClubsFetchFailed;
    ClubsActions.fetchClubs = FetchClubs;
})(ClubsActions || (ClubsActions = {}));
