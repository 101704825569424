var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { FirstLetterUppercased } from '../../lib/helpers';
import { PlayerListContainerStyle, TableStyle } from './../PlayerList/style';
var TransfersList = /** @class */ (function (_super) {
    __extends(TransfersList, _super);
    function TransfersList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    TransfersList.prototype.render = function () {
        var _a = this.props, data = _a.data, size = _a.size, showHeader = _a.showHeader, isLoading = _a.isLoading, showWeek = _a.showWeek;
        var columns = [
            {
                title: '#',
                key: 'number',
                dataIndex: 'number',
                width: '20%',
                render: function (_text, _record, index) {
                    return React.createElement("b", null, index + 1);
                },
            },
            {
                key: 'outPlayer',
                title: 'Uit',
                width: showWeek ? '30%' : '40%',
                dataIndex: 'outPlayer',
                render: function (_text, record) {
                    var playerName = (record.outPlayer &&
                        record.outPlayer.surname + " " + (record.outPlayer.forename && FirstLetterUppercased(record.outPlayer.forename)) + ".") ||
                        '';
                    return (React.createElement(React.Fragment, null,
                        React.createElement("span", { style: { display: 'block', fontSize: '12px' } }, playerName)));
                },
            },
            {
                key: 'inPlayer',
                title: 'In',
                width: showWeek ? '30%' : '40%',
                dataIndex: 'inPlayer',
                render: function (_text, record) {
                    var playerName = (record.inPlayer &&
                        record.inPlayer.surname + " " + (record.inPlayer.forename && FirstLetterUppercased(record.inPlayer.forename)) + ".") ||
                        '';
                    return (React.createElement(React.Fragment, null,
                        React.createElement("span", { style: { fontSize: '12px' } }, playerName)));
                },
            },
        ];
        if (showWeek) {
            columns.push({
                key: 'weekId',
                title: 'Rit',
                width: '20%',
                dataIndex: 'weekId',
                render: function (text) {
                    return React.createElement("b", null, text);
                },
            });
        }
        return (React.createElement(PlayerListContainerStyle, null,
            React.createElement(TableStyle, { columns: columns, dataSource: data, showHeader: showHeader, locale: { emptyText: 'Geen transfers.' }, loading: isLoading, pagination: data.length > size ? { pageSize: size } : false, rowKey: function (_record, index) { return "record-" + (index + 1); }, rowClassName: function (_record, index) {
                    return index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even';
                } })));
    };
    return TransfersList;
}(Component));
export default TransfersList;
