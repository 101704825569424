import {DefaultTheme,Theme} from '@fanarena/shared'

const SporzaWielermanagerTheme: Theme = {
    ...DefaultTheme,
    positionGk: '#4ab969',
    positionDf: '#2cb3c7',
    positionMf: '#ffc333',
    positionFw: '#fb6b5b'
}

export default SporzaWielermanagerTheme;