var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Icon } from 'antd';
import React, { Component } from 'react';
import { RoundNextHalf } from '../../lib/helpers';
import { PlayerType } from '../Player';
import PlayerPortrait from '../PlayerPortrait';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Popover from '../UI/Popover';
import Select from '../UI/Select';
import { PlayerListContainerStyle, PlayerStyle, SelectGroupStyle, TableStyle } from './style';
var PlayerList = /** @class */ (function (_super) {
    __extends(PlayerList, _super);
    function PlayerList(props) {
        var _this = _super.call(this, props) || this;
        _this.onFilterChange = function (name, value) {
            var _a;
            var filters = Object.assign({}, _this.state.filters, (_a = {},
                _a[name] = value,
                _a));
            if (_this.props.activePositionFilter && _this.props.setActivePositionFilter && name === 'position') {
                _this.props.setActivePositionFilter(value);
            }
            else {
                _this.setState({ filters: filters });
            }
        };
        _this.playerFilter = function (player, filters) {
            var show = true;
            if (filters.search.length && player.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
                show = false;
            }
            if (filters.club !== -1 && filters.club !== player.clubId) {
                show = false;
            }
            if (filters.position !== -1 && filters.position !== player.positionId) {
                show = false;
            }
            if (filters.budget !== -1 && player.value > filters.budget) {
                show = false;
            }
            return show;
        };
        _this.state = {
            filters: {
                search: '',
                budget: -1,
                club: -1,
                position: props.activePositionFilter || -1
            },
        };
        return _this;
    }
    PlayerList.prototype.render = function () {
        var _this = this;
        var _a = this.props, data = _a.data, action = _a.action, size = _a.size, showHeader = _a.showHeader, clubs = _a.clubs, hidePositions = _a.hidePositions, playerType = _a.playerType, isLoading = _a.isLoading, assetsCdn = _a.assetsCdn, actionLabel = _a.actionLabel, playerTax = _a.playerTax;
        var clubsList = [{ id: -1, name: 'Alle ploegen' }].concat(clubs);
        var playersList = data.filter(function (player) { return _this.playerFilter(player, _this.state.filters); });
        var budgets = [
            { text: 'Onbeperkt budget', value: 100 },
            { text: 'Max 8 miljoen', value: 8 },
            { text: 'Max 7 miljoen', value: 7 },
            { text: 'Max 6 miljoen', value: 6 },
            { text: 'Max 5 miljoen', value: 5 },
            { text: 'Max 4 miljoen', value: 4 },
            { text: 'Max 3 miljoen', value: 3 },
            { text: 'Max 2.5 miljoen', value: 2.5 },
        ];
        var positions = [
            { id: -1, name: 'Alle posities' },
            { id: 1, name: 'Doelman' },
            { id: 2, name: 'Verdediger' },
            { id: 3, name: 'Middenvelder' },
            { id: 4, name: 'Aanvaller' },
        ];
        var columns = [
            {
                title: '',
                key: 'avatar',
                dataIndex: 'avatar',
                width: 65,
                render: function (_text, record) {
                    var icon = assetsCdn + "/jerseys/cycling/" + record.clubId + ".png";
                    return React.createElement(PlayerPortrait, { icon: icon, type: playerType || PlayerType.SoccerPortrait });
                },
            },
            {
                key: 'name',
                title: 'Player',
                dataIndex: 'name',
                render: function (_text, record) {
                    var club = clubs.find(function (club) { return club.id === record.clubId; });
                    var position = positions.find(function (position) { return position.id === record.positionId; });
                    return (React.createElement(React.Fragment, null,
                        React.createElement(PlayerStyle, { type: "mobile", clubColor: '#9FA8AD', position: record.positionId },
                            React.createElement("p", null,
                                record.name,
                                ' ',
                                record.schedule ? (React.createElement("a", { href: record.schedule },
                                    React.createElement(Icon, { type: "info-circle" }))) : null)),
                        React.createElement(PlayerStyle, { type: "desktop", clubColor: '#9FA8AD', position: record.positionId },
                            React.createElement("p", null,
                                record.name,
                                ' ',
                                record.schedule ? (React.createElement("a", { href: record.schedule },
                                    React.createElement(Icon, { type: "info-circle" }))) : null),
                            React.createElement("p", null,
                                club && club.name,
                                React.createElement("span", null, (!hidePositions && position && position.name) || null)))));
                },
            },
            {
                key: 'value',
                title: 'Value',
                dataIndex: 'value',
                render: function (playerValue) {
                    var calculatedPlayerValue = !playerTax
                        ? playerValue
                        : RoundNextHalf(playerValue + (playerValue * playerTax) / 100);
                    var infoValueContent = (React.createElement("div", null,
                        (playerTax && (React.createElement("span", null, calculatedPlayerValue + "M = " + playerValue + "M + " + (calculatedPlayerValue - playerValue) + "M (" + playerTax + "% belasting)"))) ||
                            null,
                        React.createElement("br", null),
                        (playerTax && (React.createElement("i", { style: { fontSize: '10px' } }, "Afgerond van " + ((playerValue * playerTax) / 100).toFixed(2) + "M naar " + (calculatedPlayerValue - playerValue) + "M"))) ||
                            null));
                    return (React.createElement("span", null,
                        "\u20AC",
                        calculatedPlayerValue,
                        "M",
                        (playerTax && (React.createElement(Popover, { content: infoValueContent, title: false },
                            React.createElement(Icon, { style: { paddingLeft: '5px', cursor: 'pointer' }, type: "info-circle", theme: "filled" })))) ||
                            null));
                },
            },
        ];
        if (action) {
            columns.push({
                key: 'action',
                title: 'Pick player',
                dataIndex: 'action',
                render: function (_text, record) {
                    return ((_this.props.isPickAble && _this.props.isPickAble(record) && (React.createElement(Button, { onClick: function () { return _this.props.onPick && _this.props.onPick(record); }, size: "small" }, actionLabel || 'Kies'))) || React.createElement("span", null));
                },
            });
        }
        var tableEventHandler = function () { return null; };
        if (this.props.onSelect) {
            tableEventHandler = function (player) { return ({
                onClick: function () {
                    _this.props.onSelect(player);
                },
            }); };
        }
        return (React.createElement(PlayerListContainerStyle, null,
            React.createElement(Input, { type: "text", size: "large", placeholder: "Zoeken...", name: "search", onChange: function (event) { return _this.onFilterChange(event.target.name, event.target.value); }, style: { marginBottom: 15 }, maxLength: 64 }),
            React.createElement(SelectGroupStyle, null,
                React.createElement(Select, { block: true, values: clubsList, keyProperty: 'id', onSelect: function (value) { return _this.onFilterChange('club', value); }, textProperty: 'name', placeholder: clubsList[0].name }),
                (!hidePositions && (React.createElement(Select, { block: true, keyProperty: 'id', textProperty: 'name', onSelect: function (value) { return _this.onFilterChange('position', value); }, values: positions, placeholder: positions[0].name }))) ||
                    null,
                React.createElement(Select, { block: true, values: budgets, keyProperty: 'value', onSelect: function (value) { return _this.onFilterChange('budget', value); }, textProperty: 'text', placeholder: budgets[0].text })),
            React.createElement(TableStyle, { columns: columns, dataSource: playersList, showHeader: showHeader, locale: { emptyText: 'Geen renners gevonden.' }, loading: isLoading, pagination: { pageSize: size }, onRow: tableEventHandler, rowKey: "id", rowClassName: function (_record, index) {
                    return (index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even') + " " + (_this.props.onSelect ? 'cursor-pointer' : '');
                } })));
    };
    return PlayerList;
}(Component));
export default PlayerList;
