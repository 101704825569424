import axios from 'axios';
// import jwtDecode from "jwt-decode";
import lockr from 'lockr';
import config from './../config';
axios.interceptors.request.use(function (axiosConfig) {
    return axiosConfig;
    // console.log("REQ INTERCEPTOR INVOKED")
    // if (lockr.get('token')) {
    //     const user = jwtDecode<any>(lockr.get('token'));
    //     if (lockr.get('user') && (window.VRT && window.VRT.SSOController) && Date.now() >= user.exp * 1000) {
    //         console.log("EXPIRED TOKEN");
    //         console.log(lockr.get('token'));
    //         return window.VRT.SSOController.getNewIdToken(function (res: any, err: any) {
    //             if (err) {
    //                 console.log(err);
    //                 lockr.rm('token');
    //                 lockr.rm('user');
    //                 return axiosConfig;
    //                 //basically we will get a failed request here!
    //             }
    //             //update token localstorage
    //             console.log("NEW TOKEN", res);
    //             lockr.set('token', res);
    //             //update axios headers
    //             axiosConfig.headers.Authorization = res;
    //             // const CancelToken = axios.CancelToken;
    //             // return axios.request({
    //             //     ...axiosConfig,
    //             //     cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request'))
    //             //   });
    //             return axiosConfig;
    //             // return axios.request({
    //             //     ...axiosConfig
    //             // });
    //         });
    //     } else {
    //         console.log("VALID TOKEN");
    //         // Do something before request is sent
    //         return axiosConfig;
    //     }
    // } else {
    //     console.log("NO TOKEN");
    //     // Do something before request is sent
    //     return axiosConfig;
    // }
}, function (error) {
    console.log("INTERCEPTOR ERROR");
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
});
function requestHeaders() {
    var authToken = lockr.get('token');
    var defaultHeaders = {
        'Content-Type': 'application/json',
    };
    if (authToken) {
        return Object.assign({}, defaultHeaders, { 'Authorization': authToken });
    }
    else {
        return defaultHeaders;
    }
}
function post(url, data) {
    var headers = requestHeaders();
    data.widgetUser = lockr.get('widgetUser');
    return axios({ method: 'post', url: config.API_URL + "/" + url, data: data, headers: headers })
        .then(function (res) { return res.data; });
}
function get(url, params) {
    var headers = requestHeaders();
    return axios({ method: 'get', url: config.API_URL + "/" + url, params: params, headers: headers })
        .then(function (res) { return res.data; });
}
function put(url, data) {
    var headers = requestHeaders();
    data.widgetUser = lockr.get('widgetUser');
    return axios({ method: 'put', url: config.API_URL + "/" + url, data: data, headers: headers })
        .then(function (res) { return res.data; });
}
function deleteRequest(url, params) {
    var headers = requestHeaders();
    return axios({ method: 'delete', url: config.API_URL + "/" + url, params: params, headers: headers })
        .then(function (res) { return res.data; });
}
var http = {
    post: post,
    get: get,
    put: put,
    delete: deleteRequest
};
export default http;
