var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GAMEDATA_FETCH_FAILED, GAMEDATA_FETCHED_WITH_SUCCESS, GAMEDATA_LOADING_STARTED } from '../actions/actions-types';
var emptyGameData = {
    clubs: [],
    players: [],
    matches: [],
};
var defaultState = {
    isFetching: false,
    loaded: false,
    data: emptyGameData,
};
export function gameDataReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case GAMEDATA_LOADING_STARTED:
            return __assign(__assign({}, state), { isFetching: true, loaded: false });
        case GAMEDATA_FETCH_FAILED:
            return __assign(__assign({}, state), { isFetching: false, loaded: true });
        case GAMEDATA_FETCHED_WITH_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, data: action.data, loaded: true });
        default:
            return state;
    }
}
