export var sizes = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 900,
    laptop: 1024,
    desktop: 1200,
    desktopL: 1500
};
var mediaQueries = {
    mobileS: "(min-width: " + sizes.mobileS + "px)",
    mobileM: "(min-width: " + sizes.mobileM + "px)",
    mobileL: "(min-width: " + sizes.mobileL + "px)",
    tablet: "(min-width: " + sizes.tablet + "px)",
    laptop: "(min-width: " + sizes.laptop + "px)",
    desktop: "(min-width: " + sizes.desktop + "px)",
    desktopL: "(min-width: " + sizes.desktopL + "px)",
};
export default mediaQueries;
