var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../../styles/styled';
var H1Style = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tfont-size: 40px;\n"], ["\n\tfont-size: 40px;\n"])));
var H2Style = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tfont-size: 30px;\n"], ["\n\tfont-size: 30px;\n"])));
var H3Style = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tfont-size: 24px;\n"], ["\n\tfont-size: 24px;\n"])));
var H4Style = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tfont-size: 18px;\n\tmargin-top: 10px;\n"], ["\n\tfont-size: 18px;\n\tmargin-top: 10px;\n"])));
export { H1Style, H2Style, H3Style, H4Style };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
