var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import 'antd/lib/divider/style';
import { Divider } from 'antd';
import React, { Component } from 'react';
import { Col, Row } from '../UI/Grid';
import { Label, Value } from './style';
var TeamCreateOverview = /** @class */ (function (_super) {
    __extends(TeamCreateOverview, _super);
    function TeamCreateOverview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TeamCreateOverview.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { span: 12 },
                    React.createElement(Label, null, "Resterend budget:")),
                React.createElement(Col, { span: 12 },
                    React.createElement(Value, null,
                        React.createElement("div", { style: { textAlign: 'right' } },
                            "\u20AC",
                            this.props.budget,
                            "M")))),
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { span: 12 },
                    React.createElement(Label, null, "Geselecteerde renners:")),
                React.createElement(Col, { span: 12 },
                    React.createElement(Value, null,
                        React.createElement("div", { style: { textAlign: 'right' } },
                            this.props.totalPlayersSelected,
                            "/",
                            this.props.totalPlayers)))),
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { span: 20 },
                    React.createElement(Label, null, "Max. aantal renners van dezelfde ploeg:")),
                React.createElement(Col, { span: 4 },
                    React.createElement(Value, null,
                        React.createElement("div", { style: { textAlign: 'right' } }, this.props.teamSameClubPlayersLimit)))),
            (this.props.showRemainingTransfers && (React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Col, { span: 20 },
                    React.createElement(Label, null, "Resterende transfers:")),
                React.createElement(Col, { span: 4 },
                    React.createElement(Value, null,
                        React.createElement("div", { style: { textAlign: 'right' } }, this.props.remainingTransfers)))))) ||
                null,
            React.createElement(Row, { gutter: [16, 16] },
                React.createElement(Divider, null))));
    };
    return TeamCreateOverview;
}(Component));
export default TeamCreateOverview;
