export function StartingListToPositionsList(starting, rowConfig) {
    var result = [];
    var rows = rowConfig.length;
    for (var row = 0; row < rows; row++) {
        var totalPickedSoFar = result
            .map(function (items) { return items.length; })
            .reduce(function (acc, item) { return acc + item; }, 0);
        var lastPickedIndex = totalPickedSoFar;
        var sliceFrom = row === 0 ? 0 : lastPickedIndex;
        var sliceTo = row === 0 ? rowConfig[row] : lastPickedIndex + rowConfig[row];
        var items = starting.slice(sliceFrom, sliceTo);
        result.push(items);
    }
    return result;
}
export function FirstLetterUppercased(string) {
    return string.charAt(0).toUpperCase();
}
export function RoundNextHalf(number) {
    var integerPart = Math.floor(number);
    var decimalPart = number - integerPart;
    if (decimalPart === 0) {
        return integerPart;
    }
    else if (decimalPart <= 0.5) {
        return integerPart + 0.5;
    }
    else {
        return integerPart + 1;
    }
}
export var Helpers;
(function (Helpers) {
    Helpers.startingListToPositionsList = StartingListToPositionsList;
    Helpers.firstLetterUppercased = FirstLetterUppercased;
    Helpers.roundNextHalf = RoundNextHalf;
})(Helpers || (Helpers = {}));
