var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { PlayerType } from '../Player';
import { PortraitBg, PortraitIcon, PortraitSoccerJersey, PortraitStyle } from './style';
var PlayerPortrait = /** @class */ (function (_super) {
    __extends(PlayerPortrait, _super);
    function PlayerPortrait() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayerPortrait.prototype.render = function () {
        var _a = this.props, type = _a.type, icon = _a.icon, smallBottomLeftIcon = _a.smallBottomLeftIcon, portraitFace = _a.portraitFace, size = _a.size, style = _a.style;
        var iconSize = !size ? '50px' : size;
        return (React.createElement(PortraitStyle, { style: style },
            type === PlayerType.Cycling && React.createElement(PortraitBg, { bg: icon, size: iconSize }),
            type === PlayerType.SoccerShirt && React.createElement(PortraitBg, { bg: icon, size: iconSize }),
            type === PlayerType.SoccerPortrait && React.createElement(PortraitBg, { bg: portraitFace, size: iconSize }),
            type === PlayerType.SoccerPortrait && React.createElement(PortraitSoccerJersey, { bg: icon }),
            smallBottomLeftIcon && React.createElement(PortraitIcon, { icon: smallBottomLeftIcon })));
    };
    return PlayerPortrait;
}(Component));
export default PlayerPortrait;
