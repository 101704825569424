import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { Redux } from '@fanarena/shared';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import App from './App';
import Config from './config';
import reportWebVitals from './reportWebVitals';

const store = createStore(Redux.Reducers, applyMiddleware(thunkMiddleware));
const bugsnagClient = bugsnag(Config.BUGSNAG_KEY);
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
