var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'antd/lib/select/style';
import { Select } from 'antd';
import styled from '../../../styles/styled';
var SelectStyle = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &.ant-select-dropdown-menu-item {\n        color: #FFF;\n    }\n    width: 200px;\n\n    ", "\n\n    &.ant-select-open .ant-select-selection, .ant-select-selection:focus {\n        border: none;\n        box-shadow: none;\n    }\n\n    .ant-select-selection {\n        border-radius: 8px;\n        background: #3C3C3C;\n        border: none;\n\n        background: #3C3C3C url(/assets/img/dropdown-action.svg) right 15px center no-repeat;\n\n        .ant-select-selection__placeholder {\n            font-weight: bold;\n            color: #FFF;\n        }\n    }\n\n    &.ant-select-disabled .ant-select-selection {\n        background: #F9F9F9 url(/assets/img/dropdown-action-disabled.svg) right 15px center no-repeat;\n        \n        .ant-select-selection__placeholder {\n            color: #ababab;\n        }\n    }\n"], ["\n    &.ant-select-dropdown-menu-item {\n        color: #FFF;\n    }\n    width: 200px;\n\n    ",
    "\n\n    &.ant-select-open .ant-select-selection, .ant-select-selection:focus {\n        border: none;\n        box-shadow: none;\n    }\n\n    .ant-select-selection {\n        border-radius: 8px;\n        background: #3C3C3C;\n        border: none;\n\n        background: #3C3C3C url(/assets/img/dropdown-action.svg) right 15px center no-repeat;\n\n        .ant-select-selection__placeholder {\n            font-weight: bold;\n            color: #FFF;\n        }\n    }\n\n    &.ant-select-disabled .ant-select-selection {\n        background: #F9F9F9 url(/assets/img/dropdown-action-disabled.svg) right 15px center no-repeat;\n        \n        .ant-select-selection__placeholder {\n            color: #ababab;\n        }\n    }\n"])), function (props) { return props.block === true && "\n        width: 100%;\n    "; });
var selectTopMarginOnSmallerScreens = function (component) { return styled(component)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    &.ant-select {\n        @media (max-width: 768px) {\n            margin-top: 10px;\n        }\n    }\n"], ["\n    &.ant-select {\n        @media (max-width: 768px) {\n            margin-top: 10px;\n        }\n    }\n"]))); };
var OptionStyle = styled(Select.Option)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export { SelectStyle, OptionStyle, selectTopMarginOnSmallerScreens };
var templateObject_1, templateObject_2, templateObject_3;
