import { Redux } from '@fanarena/shared';
import { Row } from 'antd';
import lockr from 'lockr';
import { pick } from 'lodash';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import config from './../../config';

type Props = WithStateToProps & {
    location: any;
    onSetProfileData: any;
    fetchUserTeams: any;
};

class VrtCallback extends Component<Props> {
    state = {
        redirectToHome: false,
    };

    componentDidMount() {
        const params = qs.parse(this.props.location.search);
        const code = params.code;
        const state = params.state;
        const authState = lockr.get('authState');
        const userAgent = (navigator && navigator.userAgent) || '';

        if (code && state && state === authState) {
            Redux.Actions.User.vrtLogin(code as string, config.VRT?.redirectURI, state as string, userAgent as string)
                .then((result: any) => {
                    if (result && result.user.email && result.user.email.length && (window as any).$crisp && (window as any).$crisp.push) {
                        (window as any).$crisp.push(["set", "user:email", result.user.email]);
                    }

                    const user = pick(result.user, ['id', 'firstName', 'lastName']);
                    lockr.set('token', result.token);
                    lockr.set('user', user);
                    lockr.rm('authState');
                    this.props.onSetProfileData(user);
                    this.props.fetchUserTeams(this.props.application.competition.competitionFeed);
                })
                .catch(error => {
                    this.setState({ redirectToHome: true });
                });
        } else {
            lockr.rm('authState');
            this.setState({ redirectToHome: true });
        }
    }

    render() {
        return (
            <Row>
                <Redirect to={{ pathname: `/home` }} />

                {/* {this.props.user.authenticated && this.props.user.fetched && <Redirect to={{ pathname: `/new` }} />} */}
            </Row>
        );
    }
}

export const mapDispatchToProps = {
    onSetProfileData: (data: any) => (dispatch: any) => dispatch(Redux.Actions.User.setProfileData(data)),
    fetchUserTeams: Redux.Actions.User.fetchTeams,
};

function mapStateToProps({ user, application }: Redux.StoreState) {
    return {
        user,
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VrtCallback);
