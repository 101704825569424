// import { players } from '../../cache';
import http from '../../lib/http';
import { PLAYERS_FETCH_FAILED, PLAYERS_FETCHED_WITH_SUCCESS, PLAYERS_LOADING_STARTED } from './actions-types';
export function LoadPlayers() {
    return {
        type: PLAYERS_LOADING_STARTED,
    };
}
export function PlayersFetchedWithSucces(data) {
    return {
        type: PLAYERS_FETCHED_WITH_SUCCESS,
        data: data,
    };
}
export function PlayersFetchFailed() {
    return {
        type: PLAYERS_FETCH_FAILED,
    };
}
export function FetchPlayers(competitionFeed, seasonId) {
    return function (dispatch) {
        dispatch(LoadPlayers());
        //dispatch(playersFetchedWithSucces(players));
        return http.get("players", { competitionFeed: competitionFeed, seasonId: seasonId })
            .then(function (result) {
            dispatch(PlayersFetchedWithSucces(result.players));
        })
            .catch(function (error) {
            console.log(error);
            dispatch(PlayersFetchFailed());
        });
    };
}
export function GetPlayerDetails(competitionFeed, seasonId, playerId) {
    return http.get("player/" + playerId, { competitionFeed: competitionFeed, seasonId: seasonId });
}
export var PlayersActions;
(function (PlayersActions) {
    PlayersActions.loadPlayers = LoadPlayers;
    PlayersActions.playersFetchedWithSucces = PlayersFetchedWithSucces;
    PlayersActions.playersFetchFailed = PlayersFetchFailed;
    PlayersActions.fetchPlayers = FetchPlayers;
    PlayersActions.getPlayerDetails = GetPlayerDetails;
})(PlayersActions || (PlayersActions = {}));
