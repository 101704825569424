import { notification } from 'antd';
import http from '../../lib/http';
import { Actions } from './index';
export function Add(competitionFeed, seasonId, teamName, starting, bench, captainId, userNames) {
    return function (dispatch) {
        dispatch(Actions.User.loadTeams());
        return http
            .post('team/add', { competitionFeed: competitionFeed, seasonId: seasonId, teamName: teamName, starting: starting, bench: bench, captainId: captainId, userNames: userNames })
            .then(function (createdTeam) {
            dispatch(Actions.User.teamsFetchedWithSuccess([createdTeam]));
            notification.success({ message: 'Ploeg aangemaakt.' });
        })
            .catch(function () {
            dispatch(Actions.User.teamsFetchFailed());
            notification.warning({
                message: 'Je ploeg kan niet bewaard worden. Probeer later opnieuw. Contacteer ons als dit probleem blijft duren.',
            });
        });
    };
}
export function Get(teamId, weekId) {
    var params = {
    // _t: new Date().getTime(),
    };
    if (weekId) {
        params.weekId = weekId;
    }
    return http.get("team/" + teamId, params);
}
export function Points(teamId, weekId) {
    var params = {};
    return http.get("team/" + teamId + "/points/" + weekId, params);
}
export function UpdateTeamName(teamId, teamName) {
    return http
        .post("team/" + teamId + "/name", { teamName: teamName })
        .then(function () {
        notification.success({ message: 'Ploegnaam aangepast.' });
        return true;
    })
        .catch(function () {
        notification.warning({ message: 'Ploegnaam kan niet worden aangepast.' });
    });
}
export function Edit(teamId, competitionFeed, seasonId, teamName, starting, bench, captainId, userNames) {
    return http
        .post("team/" + teamId, {
        competitionFeed: competitionFeed,
        seasonId: seasonId,
        teamName: teamName,
        starting: starting,
        bench: bench,
        captainId: captainId,
        userNames: userNames
    })
        .then(function () {
        notification.success({ message: 'Ploeg aangepast.' });
    })
        .catch(function () {
        notification.warning({ message: 'Ploeg kan niet worden aangepast.' });
    });
}
export function EditWeekSelections(teamId, competitionFeed, seasonId, teamName, starting, bench, captainId, weekId) {
    return http
        .post("team/" + teamId + "/selections", {
        competitionFeed: competitionFeed,
        seasonId: seasonId,
        teamName: teamName,
        starting: starting,
        bench: bench,
        captainId: captainId,
        weekId: weekId,
    })
        .then(function () {
        notification.success({ message: 'Ploeg aangepast.' });
    })
        .catch(function () {
        notification.warning({ message: 'Ploeg kan niet worden aangepast.' });
    });
}
export function SubmitTransfers(teamId, transfers) {
    return http
        .post("team/transfers/" + teamId, { transfers: transfers })
        .then(function () {
        notification.success({ message: 'Transfer bewaard.' });
    })
        .catch(function () {
        notification.warning({ message: 'Transfers kunnen niet bewaard worden.' });
    });
}
export function ResetTransfers(teamId) {
    return http
        .post("team/transfers/" + teamId + "/reset", {})
        .then(function () {
        notification.success({ message: 'Transfers gewist.' });
    })
        .catch(function () {
        notification.warning({ message: 'Could not reset transfers.' });
    });
}
export function GetRankings(competitionFeed, rankingType, pageRecords, pageNumber, name, weekId, rankingTypeId) {
    return http.get("redis/" + rankingType, {
        // return http.get(`rankings/${rankingType}`, {
        pageRecords: pageRecords,
        pageNumber: pageNumber,
        name: name,
        weekId: weekId,
        competitionFeed: competitionFeed,
        rankingTypeId: rankingTypeId,
    });
}
export var TeamsActions;
(function (TeamsActions) {
    TeamsActions.add = Add;
    TeamsActions.get = Get;
    TeamsActions.points = Points;
    TeamsActions.updateTeamName = UpdateTeamName;
    TeamsActions.edit = Edit;
    TeamsActions.editWeekSelections = EditWeekSelections;
    TeamsActions.submitTransfers = SubmitTransfers;
    TeamsActions.resetTransfers = ResetTransfers;
    TeamsActions.getRankings = GetRankings;
})(TeamsActions || (TeamsActions = {}));
