var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../styles/styled';
var PortraitStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    height: ", ";\n    width: ", ";\n"], ["\n    position: relative;\n    height: ", ";\n    width: ", ";\n"])), function (props) { return props.parentSize || '55px'; }, function (props) { return props.parentSize || '55px'; });
var PortraitBg = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: 2.5px;\n    left: 2.5px;\n    \n    height: ", " ;\n    width: ", " ;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    background: url(", ") no-repeat center center/contain;\n"], ["\n    position: absolute;\n    top: 2.5px;\n    left: 2.5px;\n    \n    height: ", " ;\n    width: ", " ;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    background: url(", ") no-repeat center center/contain;\n"])), function (props) { return "" + props.size; }, function (props) { return "" + props.size; }, function (props) { return props.bg; });
var PortraitSoccerJersey = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: absolute;\n    bottom: -22.5px;\n    height: 55px;\n    width: 55px;\n    background: url(", ") no-repeat center center/contain;\n"], ["\n    position: absolute;\n    bottom: -22.5px;\n    height: 55px;\n    width: 55px;\n    background: url(", ") no-repeat center center/contain;\n"])), function (props) { return props.bg; });
var PortraitIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    bottom: 0;\n    left: -5px;\n    width: 20px;\n    height: 20px;\n    background: url(", ") no-repeat center center/contain;\n"], ["\n    position: absolute;\n    bottom: 0;\n    left: -5px;\n    width: 20px;\n    height: 20px;\n    background: url(", ") no-repeat center center/contain;\n"])), function (props) { return props.icon; });
export { PortraitBg, PortraitStyle, PortraitSoccerJersey, PortraitIcon };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
