import lockr from 'lockr';
import http from '../../lib/http';
import { Actions } from './';
import { LOG_OUT, SET_PROFILE_DATA, USER_LEAGUES_FETCH_FAILED, USER_LEAGUES_FETCHED_WITH_SUCCESS, USER_LEAGUES_LOADING_STARTED, USER_TEAMS_FETCH_FAILED, USER_TEAMS_FETCHED_WITH_SUCCESS, USER_TEAMS_LOADING_STARTED, } from './actions-types';
export function SetProfileData(data) {
    return {
        type: SET_PROFILE_DATA,
        data: data,
    };
}
export function LogOut() {
    return {
        type: LOG_OUT,
    };
}
export function LoadTeams() {
    return {
        type: USER_TEAMS_LOADING_STARTED,
    };
}
export function TeamsFetchedWithSuccess(teams) {
    return {
        type: USER_TEAMS_FETCHED_WITH_SUCCESS,
        teams: teams,
    };
}
export function TeamsFetchFailed() {
    return {
        type: USER_TEAMS_FETCH_FAILED,
    };
}
export function LoadLeagues() {
    return {
        type: USER_LEAGUES_LOADING_STARTED,
    };
}
export function LeaguesFetchedWithSuccess(leagues) {
    return {
        type: USER_LEAGUES_FETCHED_WITH_SUCCESS,
        leagues: leagues,
    };
}
export function LeaguesFetchFailed() {
    return {
        type: USER_LEAGUES_FETCH_FAILED,
    };
}
export function FetchUserTeamLeaguesAndPersist(teamId) {
    return function (dispatch) {
        dispatch(Actions.User.loadLeagues());
        return Actions.Leagues.fetchTeamLeagues(teamId)
            .then(function (result) {
            dispatch(Actions.User.leaguesFetchedWithSuccess(result.leagues));
        })
            .catch(function () {
            dispatch(Actions.User.leaguesFetchFailed());
        });
    };
}
export function FetchTeams(competitionFeed) {
    return function (dispatch) {
        dispatch(LoadTeams());
        return http
            .get("teams", { competitionFeed: competitionFeed })
            .then(function (result) {
            dispatch(TeamsFetchedWithSuccess(result.teams));
            if (result.user) {
                dispatch(SetProfileData(result.user));
            }
        })
            .catch(function () {
            dispatch(TeamsFetchFailed());
        });
    };
}
export function FetchHistory(competitionFeed) {
    return http.get("teams?competitionFeed=" + competitionFeed, {});
}
export function VrtState(userAgent) {
    return http.post("user/auth/vrt/state", { userAgent: userAgent });
}
export function VrtLogin(code, redirectURI, state, userAgent) {
    return http.post("user/auth/vrt", { code: code, redirectURI: redirectURI, state: state, userAgent: userAgent });
}
export function VrtWidgetLogin(widgetToken, email, given_name, family_name) {
    return http.post("user/auth/vrt-widget", { widgetToken: widgetToken, email: email, given_name: given_name, family_name: family_name });
}
export function LogOutUser() {
    return function (dispatch) {
        dispatch(LogOut());
        lockr.rm('token');
        lockr.rm('user');
    };
}
export function GetPage(competitionFeed, slug) {
    return http.get("pages?competitionFeed=" + competitionFeed + "&slug=" + slug, {});
}
export function GetPrizes(competitionFeed) {
    return http.get("prizes?competitionFeed=" + competitionFeed, {});
}
export var UserActions;
(function (UserActions) {
    UserActions.setProfileData = SetProfileData;
    UserActions.logOut = LogOut;
    UserActions.loadTeams = LoadTeams;
    UserActions.teamsFetchedWithSuccess = TeamsFetchedWithSuccess;
    UserActions.teamsFetchFailed = TeamsFetchFailed;
    UserActions.loadLeagues = LoadLeagues;
    UserActions.leaguesFetchedWithSuccess = LeaguesFetchedWithSuccess;
    UserActions.leaguesFetchFailed = LeaguesFetchFailed;
    UserActions.fetchUserTeamLeaguesAndPersist = FetchUserTeamLeaguesAndPersist;
    UserActions.fetchTeams = FetchTeams;
    UserActions.vrtState = VrtState;
    UserActions.vrtLogin = VrtLogin;
    UserActions.vrtWidgetLogin = VrtWidgetLogin;
    UserActions.logOutUser = LogOutUser;
    UserActions.getPage = GetPage;
    UserActions.getPrizes = GetPrizes;
    UserActions.fetchHistory = FetchHistory;
})(UserActions || (UserActions = {}));
