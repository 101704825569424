// Application
export var SWITCH_COMPETITION_FEED = 'SWITCH_COMPETITION_FEED';
// User
export var SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export var LOG_OUT = 'LOG_OUT';
export var USER_TEAMS_LOADING_STARTED = 'USER_TEAMS_LOADING_STARTED';
export var USER_TEAMS_FETCHED_WITH_SUCCESS = 'USER_TEAMS_FETCHED_WITH_SUCCESS';
export var USER_TEAMS_FETCH_FAILED = 'USER_TEAMS_FETCH_FAILED';
export var USER_LEAGUES_LOADING_STARTED = 'USER_LEAGUES_LOADING_STARTED';
export var USER_LEAGUES_FETCHED_WITH_SUCCESS = 'USER_LEAGUES_FETCHED_WITH_SUCCESS';
export var USER_LEAGUES_FETCH_FAILED = 'USER_LEAGUES_FETCH_FAILED';
// Game data
export var GAMEDATA_LOADING_STARTED = 'GAMEDATA_LOADING_STARTED';
export var GAMEDATA_FETCHED_WITH_SUCCESS = 'GAMEDATA_FETCHED_WITH_SUCCESS';
export var GAMEDATA_FETCH_FAILED = 'GAMEDATA_FETCH_FAILED';
// Players
export var PLAYERS_LOADING_STARTED = 'PLAYERS_LOADING_STARTED';
export var PLAYERS_FETCHED_WITH_SUCCESS = 'PLAYERS_FETCHED_WITH_SUCCESS';
export var PLAYERS_FETCH_FAILED = 'PLAYERS_FETCH_FAILED';
// Clubs
export var CLUBS_LOADING_STARTED = 'CLUBS_LOADING_STARTED';
export var CLUBS_FETCHED_WITH_SUCCESS = 'CLUBS_FETCHED_WITH_SUCCESS';
export var CLUBS_FETCH_FAILED = 'CLUBS_FETCH_FAILED';
// Matches
export var MATCHES_INFO_LOADING_STARTED = 'MATCHES_INFO_LOADING_STARTED';
export var MATCHES_INFO_FETCHED_WITH_SUCCESS = 'MATCHES_INFO_FETCHED_WITH_SUCCESS';
export var MATCHES_INFO_FETCH_FAILED = 'MATCHES_INFO_FETCH_FAILED';
export var MATCHES_FETCHED_WITH_SUCCESS = 'MATCHES_FETCHED_WITH_SUCCESS';
