var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Button from '../UI/Button';
import Link from '../UI/Link';
import { PlayerListContainerStyle, TableStyle } from './../PlayerList/style';
var LeaguesList = /** @class */ (function (_super) {
    __extends(LeaguesList, _super);
    function LeaguesList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    LeaguesList.prototype.render = function () {
        var _this = this;
        var _a = this.props, data = _a.data, size = _a.size, showHeader = _a.showHeader, isLoading = _a.isLoading, leagueNameAsLink = _a.leagueNameAsLink;
        var columns = [
            {
                title: '#',
                key: 'recordNumber',
                dataIndex: 'recordNumber',
                width: '20%',
                render: function (text) {
                    return React.createElement("b", null, text);
                },
            },
            {
                key: 'name',
                title: '',
                width: '80%',
                dataIndex: 'name',
                render: function (text, record) {
                    return leagueNameAsLink ? React.createElement(Link, { to: "/leagues/" + record.id }, text) || null : React.createElement("b", null, text);
                },
            },
            {
                key: 'action',
                title: 'View league',
                dataIndex: 'action',
                render: function (_text, record) {
                    var joinIgnoreLeagueIds = _this.props.joinIgnoreLeagueIds || [];
                    return ((_this.props.onView && (React.createElement(Button, { onClick: function () { return _this.props.onView(record); }, size: "small" }, "View"))) ||
                        (_this.props.onJoin && joinIgnoreLeagueIds.indexOf(record.id) === -1 && (React.createElement(Button, { onClick: function () { return _this.props.onJoin(record); }, size: "small" }, "Doe mee"))) || React.createElement("span", null));
                },
            },
        ];
        return (React.createElement(PlayerListContainerStyle, null,
            React.createElement(TableStyle, { columns: columns, dataSource: data, showHeader: showHeader, locale: {
                    emptyText: this.props.noLeaguesMessage || 'Deze ploeg neemt nog niet deel aan een minicompetitie',
                }, loading: isLoading, pagination: { pageSize: size }, rowKey: "id", rowClassName: function (_record, index) {
                    return index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even';
                } })));
    };
    return LeaguesList;
}(Component));
export default LeaguesList;
