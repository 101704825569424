import { SWITCH_COMPETITION_FEED } from './actions-types';
export function SwitchCompetitionFeed(feed) {
    console.log("Switch triggered", feed);
    return {
        type: SWITCH_COMPETITION_FEED,
        feed: feed,
    };
}
export var ApplicationActions;
(function (ApplicationActions) {
    ApplicationActions.switchCompetitionFeed = SwitchCompetitionFeed;
})(ApplicationActions || (ApplicationActions = {}));
