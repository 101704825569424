var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import lockr from 'lockr';
import { LOG_OUT, SET_PROFILE_DATA, USER_LEAGUES_FETCH_FAILED, USER_LEAGUES_FETCHED_WITH_SUCCESS, USER_LEAGUES_LOADING_STARTED, USER_TEAMS_FETCH_FAILED, USER_TEAMS_FETCHED_WITH_SUCCESS, USER_TEAMS_LOADING_STARTED, } from '../actions/actions-types';
var userData = lockr.get('user');
var emptyUserData = { firstName: '', lastName: '', id: null };
var emptyUserTeams = [];
var emptyUserLeagues = [];
var defaultState = {
    authenticated: !!userData,
    isFetching: false,
    fetched: false,
    data: userData || emptyUserData,
    teams: emptyUserTeams,
    leagues: emptyUserLeagues,
    leaguesFetched: false,
};
export function userReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case SET_PROFILE_DATA:
            lockr.set('user', action.data);
            return __assign(__assign({}, state), { authenticated: true, data: action.data });
        case USER_TEAMS_FETCH_FAILED:
            return __assign(__assign({}, state), { isFetching: false, fetched: true });
        case USER_TEAMS_LOADING_STARTED:
            return __assign(__assign({}, state), { isFetching: true });
        case USER_TEAMS_FETCHED_WITH_SUCCESS:
            return __assign(__assign({}, state), { teams: action.teams, fetched: true });
        case USER_LEAGUES_FETCH_FAILED:
            return __assign(__assign({}, state), { isFetching: false, leaguesFetched: true });
        case USER_LEAGUES_LOADING_STARTED:
            return __assign(__assign({}, state), { isFetching: true });
        case USER_LEAGUES_FETCHED_WITH_SUCCESS:
            return __assign(__assign({}, state), { leagues: action.leagues, leaguesFetched: true, isFetching: false });
        case LOG_OUT:
            return __assign(__assign({}, state), { authenticated: false, data: emptyUserData, teams: emptyUserTeams });
        default:
            return state;
    }
}
