var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import 'antd/lib/grid/style';
import { Col, Row } from 'antd';
import mediaQueries from "../../../styles/media-queries";
import styled from '../../../styles/styled';
export var RowStyle = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &.ant-row {\n        @media ", " {\n            margin-left: 30px;\n            margin-right: 30px;\n        }\n    }\n"], ["\n    &.ant-row {\n        @media ", " {\n            margin-left: 30px;\n            margin-right: 30px;\n        }\n    }\n"])), mediaQueries.desktop);
export var ColStyle = styled(Col)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n"], ["\n"])));
var templateObject_1, templateObject_2;
