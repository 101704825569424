var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../styles/styled';
var StatsStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n"])));
var RowStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    &:first-child {\n        text-align: right;\n        margin-right: 1.5px;\n\n        > div {\n            justify-content: flex-end;\n\n            span {\n                margin-left: 10px;\n            }\n        }\n    }\n\n    &:last-child {\n        text-align: left;\n        margin-left: 1.5px;\n\n        > div {\n            justify-content: flex-start;\n\n            span {\n                margin-right: 10px;\n            }\n        }\n    }\n"], ["\n    &:first-child {\n        text-align: right;\n        margin-right: 1.5px;\n\n        > div {\n            justify-content: flex-end;\n\n            span {\n                margin-left: 10px;\n            }\n        }\n    }\n\n    &:last-child {\n        text-align: left;\n        margin-left: 1.5px;\n\n        > div {\n            justify-content: flex-start;\n\n            span {\n                margin-right: 10px;\n            }\n        }\n    }\n"])));
var PointStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    margin-bottom: 3px;\n\n    line-height: 1;\n    font-weight: bold;\n\n    span {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        width: 80px;\n        height: 35px;\n        background-color: black;\n\n        color: #FF286E;\n        font-size: 25px;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    margin-bottom: 3px;\n\n    line-height: 1;\n    font-weight: bold;\n\n    span {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        width: 80px;\n        height: 35px;\n        background-color: black;\n\n        color: #FF286E;\n        font-size: 25px;\n    }\n"])));
export { StatsStyle, RowStyle, PointStyle };
var templateObject_1, templateObject_2, templateObject_3;
