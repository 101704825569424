import { Button, H1, H2, H3, Redux } from '@fanarena/shared';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import VrtLogin from '../../components/VrtLogin';
import { CardStyle, HomeStyle } from './style';

type Props = WithStateToProps & {
    onSetProfileData: any;
    fetchUserTeams: any;
    logOutUser: any;
    match: any;
};

const { Meta } = Card;

class Home extends Component<Props> {
    
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
        const momentDeadlineDate = deadlineDate ? moment(deadlineDate).valueOf() : moment().valueOf();
        const deadlineMatch = this.props.matches.data.find(
            (match: any) =>
                !!(
                    this.props.matches.info &&
                    this.props.matches.info.deadlineWeek &&
                    match.weekId === this.props.matches.info.deadlineWeek
                ),
        );
        const hasDeadlineWeek = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek;

        const deadlineRenderer = ({ days, hours, minutes, seconds }: any) => {
            return (
                <H3 style={{ color: 'white' }} className="countdown">
                    {deadlineMatch ? deadlineMatch.feedUrl : ''}: {days}d {hours}u {minutes}m {seconds}sec
                </H3>
            );
        };

        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        // const redirectToPoints =
        //     this.props.match.path === '/' && team && deadlineMatch && team.weekId < deadlineMatch.weekId;

    //    console.log('this.props.application.competition', this.props.application.competition)

        return (
            <HomeStyle competitionfeed={this.props.application.competition.competitionFeed && this.props.application.competition.competitionFeed.toLowerCase()}>
                {/* {redirectToPoints && <Redirect to={{ pathname: `/points/${team.id}` }} />} */}
                <Row className='cover'>
                    <Col xs={24}>
                        <H1>Wielermanager</H1>
                        {hasDeadlineWeek && momentDeadlineDate ? (
                            <Countdown renderer={deadlineRenderer} date={momentDeadlineDate} />
                        ) : null}

                        {this.props.application.competition.inactive && (
                            <React.Fragment>
                                <span className="win">Door de overbelasting van onze servers zijn we genoodzaakt een upgrade door te voeren. We houden jullie op de hoogte van zodra het probleem is opgelost.</span>
                            </React.Fragment>
                        )}

                        {!this.props.application.competition.inactive && !this.props.user.data.id && (
                            <React.Fragment>
                                <span className="win">Stel je ploeg samen voor de Sporza Wielermanager.</span>
                                <p style={{padding: "10px 0"}}>
                                    Klik op &quot;Aanmelden&quot; om jouw ploeg te maken of beheren.
                                </p>
                                <VrtLogin id="home" theme="c4" elemStyle={{ width: '9em', margin: '10px auto', fontSize: '18px' }} />
                            </React.Fragment>
                        )}

                        {!this.props.application.competition.inactive && this.props.user.data.id && !team ? (
                        <div style={{margin: '20px 0'}}>
                            <Link to={`/game`}>
                                <Button style={{ fontSize: '18px' }}>Maak een ploeg</Button>
                            </Link>
                        </div>
                        ) : null}

                        {!this.props.application.competition.inactive && this.props.user.data.id && team ? (
                        <div style={{margin: '20px 0'}}>
                            <Link to={hasDeadlineWeek ? `/team/${team.id}` : `/points/${team.id}` } style={{margin: '20px 0'}}>
                                <Button style={{ fontSize: '18px' }}>Naar jouw ploeg</Button>
                            </Link>
                        </div>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <H2>Hoe werkt het?</H2>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="denk strategisch"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap1_giro24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Denk strategisch"
                                    description={
                                        'Stel een evenwichtig team samen van klimmers en sprinters en kies voor elk rit uit je 16 renners.'
                                        // this.props.application.competition.competitionFeed === 'KLASF' ? 
                                        //     'Kies voor elke wedstrijd een kopvrouw. Bestudeer welke rensters, welke klassiekers rijden en stel een evenwichtig team samen.' 
                                        //         : 'Kies voor elke wedstrijd 12 van je 20 renners. Bestudeer welke renners, welke klassiekers rijden en stel een evenwichtig team samen.'
                                    }
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="kies renners"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap2_giro24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Verdien punten"
                                    description="Na elke rit verdient de top 25 punten. Jouw kopman en de ploegmaats van de winnaar krijgen bonuspunten."
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="denk strategisch"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap3_giro24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Doe transfers"
                                    description={
                                        'Niet tevreden over één van je renners? Gooi hem uit jouw bus en neem een andere renner. Maar let op: je kunt maar 4 transfers doen tijdens de Giro.'
                                        // this.props.application.competition.competitionFeed === 'KLASF' ?
                                        // 'Niet tevreden over één van je rensters? Gooi ze uit jouw ploeg en neem een andere renner. Maar let op: je kunt maar 3 transfers doen tijdens de voorjaarsklassiekers én je moet op elke transfer een belasting betalen.'
                                        //  : 'Niet tevreden over één van je renners? Gooi hem uit jouw bus en neem een andere renner. Maar let op: je kunt maar 5 transfers doen tijdens de voorjaarsklassiekers én je moet op elke transfer een belasting betalen.'
                                    }
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <CardStyle>
                            <Card
                                hoverable
                                style={{ minWidth: 240 }}
                                bordered={false}
                                cover={
                                    <img
                                        alt="kies renners"
                                        src={`${this.props.application.competition.assetsCdn}/files/stap4_giro24.jpg`}
                                    />
                                }
                            >
                                <Meta
                                    title="Speel mee"
                                    description="Daag je vrienden uit in een minicompetitie en verdien zo eeuwige roem."
                                />
                            </Card>
                        </CardStyle>
                    </Col>
                </Row>
            </HomeStyle>
        );
    }
}

const mapDispatchToProps = {
    onSetProfileData: (data: any) => (dispatch: any) => dispatch(Redux.Actions.User.setProfileData(data)),
    fetchUserTeams: Redux.Actions.User.fetchTeams,
    logOutUser: Redux.Actions.User.logOutUser,
};

function mapStateToProps({ user, matches, application }: Redux.StoreState) {
    return {
        user,
        matches,
        application,
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
