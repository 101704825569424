var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { notification } from 'antd';
import lockr from 'lockr';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RoundNextHalf } from '../../lib/helpers';
import { Redux } from '../../redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
var withAbstractTeam = function (WrappedComponent) {
    var AbstractTeam = /** @class */ (function (_super) {
        __extends(AbstractTeam, _super);
        function AbstractTeam(props) {
            var _this = _super.call(this, props) || this;
            _this.setStarting = function (starting) {
                _this.setState({ starting: starting });
            };
            _this.setBench = function (bench) {
                _this.setState({ bench: bench });
            };
            _this.setTeamName = function (teamName) {
                _this.setState({ teamName: teamName });
            };
            _this.resetTeamName = function () {
                _this.setState({ teamName: _this.state.teamNameInitial, teamNameChanged: false });
            };
            _this.updateTeamName = function (teamId) {
                Redux.Actions.Teams.updateTeamName(teamId, _this.state.teamName).then(function () {
                    _this.setState({ teamNameChanged: false, teamNameInitial: _this.state.teamName });
                });
            };
            _this.setCaptainId = function (captainId) {
                _this.setState({ captainId: captainId });
            };
            _this.setBudget = function (budget) {
                _this.setState({ budget: budget });
            };
            _this.initTeamState = function (starting, bench, teamName, captainId, budget, leagues, visibleWeekId, teamPointsInfo, _rawTransfers, deadlineWeekTransfers, pastTransfers, nameVisible) {
                _this.setState({
                    starting: starting,
                    bench: bench,
                    teamName: teamName,
                    captainId: captainId,
                    budget: budget,
                    teamNameInitial: teamName,
                    initializedExternally: true,
                    leagues: leagues || _this.props.user.leagues,
                    visibleWeekId: visibleWeekId || _this.state.visibleWeekId,
                    teamPointsInfo: teamPointsInfo || _this.state.teamPointsInfo,
                    deadlineWeekTransfers: deadlineWeekTransfers || [],
                    pastTransfers: pastTransfers || [],
                    draftTransfers: [],
                    initialBench: bench,
                    initialStarting: starting,
                    initialBudget: budget,
                    nameVisible: nameVisible || false,
                });
            };
            _this.pickPlayer = function (player, taxForPicking) {
                var emptyPlayer = null;
                var startingHasEmptySpot = _this.state.starting.find(function (teamPlayer) { return !teamPlayer; }) === emptyPlayer;
                var benchHasEmptySpot = _this.state.bench.find(function (teamPlayer) { return !teamPlayer; }) === emptyPlayer;
                var playerValue = !taxForPicking
                    ? player.value
                    : RoundNextHalf(player.value +
                        (player.value * (_this.props.application.competition.transferTaxPercentage || 0)) / 100);
                if (startingHasEmptySpot) {
                    var firstEmptySpotIndexInStarting_1 = _this.state.starting.indexOf(null);
                    var starting = _this.state.starting.map(function (teamPlayer, playerIndex) {
                        if (playerIndex === firstEmptySpotIndexInStarting_1) {
                            return player;
                        }
                        else {
                            return teamPlayer;
                        }
                    });
                    var budget = _this.state.budget - playerValue;
                    _this.setState({ starting: starting, budget: budget });
                }
                else if (benchHasEmptySpot) {
                    var firstEmptySpotIndexInBench_1 = _this.state.bench.indexOf(null);
                    var bench = _this.state.bench.map(function (teamPlayer, playerIndex) {
                        if (playerIndex === firstEmptySpotIndexInBench_1) {
                            return player;
                        }
                        else {
                            return teamPlayer;
                        }
                    });
                    var budget = _this.state.budget - playerValue;
                    _this.setState({ bench: bench, budget: budget });
                }
            };
            _this.removeStartingPlayer = function (player) {
                var newState = _this.state.starting.map(function (startingPlayer) {
                    if (startingPlayer && startingPlayer.id && startingPlayer.id === player.id) {
                        return null;
                    }
                    else {
                        return startingPlayer;
                    }
                });
                var budget = _this.state.budget + player.value;
                var captainId = _this.state.captainId === player.id ? undefined : _this.state.captainId;
                _this.setState({ starting: newState, budget: budget, captainId: captainId });
            };
            _this.removeBenchPlayer = function (player) {
                var newState = _this.state.bench.map(function (benchPlayer) {
                    if (benchPlayer && benchPlayer.id && benchPlayer.id === player.id) {
                        return null;
                    }
                    else {
                        return benchPlayer;
                    }
                });
                var budget = _this.state.budget + player.value;
                _this.setState({ bench: newState, budget: budget });
            };
            _this.onCaptainSelect = function (playerId) {
                var playerIndex = null;
                _this.state.starting.forEach(function (startingPLayer, index) {
                    if (startingPLayer && startingPLayer.id === playerId) {
                        playerIndex = index;
                    }
                });
                var currentCaptain = _this.state.starting.find(function (_startingPLayer, index) { return index === 0; });
                var nextCaptain = _this.state.starting.find(function (startingPLayer) { return startingPLayer && startingPLayer.id === playerId; });
                if (playerIndex !== 0) {
                    var starting = _this.state.starting.map(function (startingPLayer, index) {
                        if (index === 0) {
                            return nextCaptain;
                        }
                        else if (index === playerIndex) {
                            return currentCaptain;
                        }
                        else {
                            return startingPLayer;
                        }
                    });
                    _this.setState({ captainId: playerId, starting: starting });
                }
                else {
                    _this.setState({ captainId: playerId });
                }
            };
            _this.onTeamNameChange = function (e) {
                _this.setState({ teamName: e.target.value, teamNameChanged: e.target.value !== _this.state.teamNameInitial });
            };
            _this.onNameVisibleChange = function (e) {
                _this.setState({ nameVisible: e.target.checked });
            };
            _this.validateTeam = function (showNotifications) {
                var valid = true;
                var hasCaptain = !!_this.state.captainId;
                var allStartingPicked = _this.state.starting.filter(function (player) { return player && player.id; }).length ===
                    _this.props.application.competition.lineupSize;
                var allBenchPicked = _this.state.bench.filter(function (player) { return player && player.id; }).length ===
                    _this.props.application.competition.benchSize;
                var teamNameValid = _this.state.teamName.length > 2;
                if (!hasCaptain) {
                    if (showNotifications) {
                        if (_this.props.application.competition.competitionFeed === 'KLASF') {
                            notification.warning({ message: 'Geen kopvrouw aangewezen.' });
                        }
                        else {
                            notification.warning({ message: 'Geen kopman aangewezen.' });
                        }
                    }
                    valid = false;
                }
                if (!allStartingPicked || !allBenchPicked) {
                    if (showNotifications) {
                        notification.warning({ message: 'Niet genoeg renners geselecteerd.' });
                    }
                    valid = false;
                }
                if (!teamNameValid) {
                    if (showNotifications) {
                        notification.warning({ message: 'Je ploegnaam moet minstens 3 tekens lang zijn.' });
                    }
                    valid = false;
                }
                return valid;
            };
            _this.onTeamSave = function () {
                var isValid = _this.validateTeam(true);
                if (isValid) {
                    var startingIds = _this.state.starting.map(function (player) { return player && player.id; });
                    var benchIds = _this.state.bench.map(function (player) { return player && player.id; });
                    var userNames = _this.state.nameVisible ? lockr.get('widgetUser') : 0;
                    _this.setState({ savingTeamPending: true });
                    _this.props.create(_this.props.application.competition.competitionFeed, _this.props.application.competition.seasonId, _this.state.teamName, startingIds, benchIds, _this.state.captainId, userNames);
                }
            };
            _this.onTeamEdit = function (teamId) {
                var isValid = _this.validateTeam(true);
                if (isValid) {
                    var startingIds = _this.state.starting.map(function (player) { return player.id; });
                    var benchIds = _this.state.bench.map(function (player) { return player.id; });
                    var userNames = _this.state.nameVisible ? lockr.get('widgetUser') : 0;
                    Redux.Actions.Teams.edit(teamId, _this.props.application.competition.competitionFeed, _this.props.application.competition.seasonId, _this.state.teamName, startingIds, benchIds, _this.state.captainId, userNames);
                }
            };
            _this.onTeamSelectionsUpdate = function (teamId, weekId) {
                var isValid = _this.validateTeam(true);
                if (isValid) {
                    var startingIds = _this.state.starting.map(function (player) { return player.id; });
                    var benchIds = _this.state.bench.map(function (player) { return player.id; });
                    Redux.Actions.Teams.editWeekSelections(teamId, _this.props.application.competition.competitionFeed, _this.props.application.competition.seasonId, _this.state.teamName, startingIds, benchIds, _this.state.captainId, weekId);
                }
            };
            _this.onDayChange = function (direction) {
                if (direction === 'next') {
                    var upcomingWeekExists = _this.props.matches.weeks.find(function (value) { return !!(_this.state.visibleWeekId && value.weekId > _this.state.visibleWeekId); });
                    if (upcomingWeekExists && _this.state.visibleWeekId) {
                        _this.setState({ visibleWeekId: _this.state.visibleWeekId + 1 });
                    }
                }
                else if (direction === 'prev') {
                    var previousWeekExists = _this.props.matches.weeks.find(function (value) { return !!(_this.state.visibleWeekId && value.weekId < _this.state.visibleWeekId); });
                    if (previousWeekExists && _this.state.visibleWeekId) {
                        _this.setState({ visibleWeekId: _this.state.visibleWeekId - 1 });
                    }
                }
                else {
                    return;
                }
            };
            _this.onPlayerSwap = function (player) {
                if (player && player.id === _this.state.swapPlayerId) {
                    _this.setState({ swapPlayerId: null, swappedFrom: null });
                }
                else if (_this.state.swapPlayerId) {
                    var previousSwapFromLineup_1 = _this.state.starting.find(function (startingPlayer) { return startingPlayer.id === _this.state.swapPlayerId; });
                    var previousSwapFromBench_1 = _this.state.bench.find(function (benchPlayer) { return benchPlayer.id === _this.state.swapPlayerId; });
                    var starting = null;
                    var bench = null;
                    var captainId = null;
                    if (previousSwapFromLineup_1) {
                        starting = _this.state.starting.map(function (startingPlayer) {
                            return startingPlayer && startingPlayer.id === _this.state.swapPlayerId
                                ? Object.assign({}, player, { inStarting: true })
                                : startingPlayer;
                        });
                        bench = _this.state.bench.map(function (benchPlayer) {
                            return benchPlayer && benchPlayer.id === player.id
                                ? Object.assign({}, previousSwapFromLineup_1, { inStarting: false })
                                : benchPlayer;
                        });
                        if (previousSwapFromLineup_1.id === _this.state.captainId) {
                            captainId = player.id;
                        }
                    }
                    else {
                        starting = _this.state.starting.map(function (startingPlayer) {
                            return startingPlayer && startingPlayer.id === player.id
                                ? Object.assign({}, previousSwapFromBench_1, { inStarting: true })
                                : startingPlayer;
                        });
                        bench = _this.state.bench.map(function (benchPlayer) {
                            return benchPlayer && benchPlayer.id === _this.state.swapPlayerId
                                ? Object.assign({}, player, { inStarting: false })
                                : benchPlayer;
                        });
                        if (player.id === _this.state.captainId) {
                            captainId = previousSwapFromBench_1.id;
                        }
                    }
                    _this.setState({
                        starting: starting,
                        bench: bench,
                        swappedFrom: null,
                        swapPlayerId: null,
                        captainId: captainId || _this.state.captainId,
                    });
                }
                else {
                    var isLineupSwap = _this.state.starting.find(function (startingPlayer) { return startingPlayer.id === player.id; });
                    _this.setState({ swapPlayerId: player.id, swappedFrom: isLineupSwap ? 'starting' : 'bench' });
                }
            };
            _this.isPickAble = function (player, taxForPicking, isTransferPick) {
                var notInStarting = !_this.state.starting.find(function (startingPlayer) { return startingPlayer && startingPlayer.id && startingPlayer.id === player.id; });
                var notInBench = !_this.state.bench.find(function (benchPlayer) { return benchPlayer && benchPlayer.id && benchPlayer.id === player.id; });
                var affordable = !taxForPicking
                    ? player.value <= _this.state.budget
                    : RoundNextHalf(player.value +
                        (player.value * (_this.props.application.competition.transferTaxPercentage || 0)) / 100) <= _this.state.budget;
                var notTransferredOut = isTransferPick
                    ? !_this.state.draftTransfers.find(function (transfer) { return transfer.outId === player.id; })
                    : true;
                var hasEmptySpotInTeam = _this.state.starting.find(function (startingPlayer) { return !startingPlayer; }) === null ||
                    _this.state.bench.find(function (benchPlayer) { return !benchPlayer; }) === null;
                var pickedSoFarFromSameClub = _this.state.starting.filter(function (startingPlayer) { return startingPlayer && startingPlayer.clubId === player.clubId; })
                    .length +
                    _this.state.bench.filter(function (benchPlayer) { return benchPlayer && benchPlayer.clubId === player.clubId; }).length;
                var notBreakingClubLimit = pickedSoFarFromSameClub < _this.props.application.competition.teamSameClubPlayersLimit;
                return (notInStarting &&
                    notInBench &&
                    affordable &&
                    hasEmptySpotInTeam &&
                    notBreakingClubLimit &&
                    notTransferredOut);
            };
            _this.loadAllMatches = function () {
                if (!_this.props.matches.data.length) {
                    _this.props.fetchMatches(_this.props.application.competition.competitionFeed, _this.props.application.competition.seasonId);
                }
            };
            _this.onTransferPlayerOut = function (player) {
                var draftTransfers = _this.state.draftTransfers.concat([
                    {
                        inId: null,
                        outId: player.id,
                        weekId: _this.props.matches.info.deadlineWeek,
                    },
                ]);
                _this.setState({ draftTransfers: draftTransfers });
            };
            _this.onTransferPlayerIn = function (player) {
                var draftTransfers = [].concat(_this.state.draftTransfers);
                for (var transferIndex = 0; transferIndex < draftTransfers.length; transferIndex++) {
                    if (!draftTransfers[transferIndex].inId) {
                        draftTransfers[transferIndex].inId = player.id;
                        break;
                    }
                }
                _this.setState({ draftTransfers: draftTransfers });
            };
            _this.onDraftTransfersClear = function () {
                _this.setState({
                    draftTransfers: [],
                    starting: _this.state.initialStarting,
                    bench: _this.state.initialBench,
                    budget: _this.state.initialBudget,
                });
            };
            _this.onTransfersSubmit = function (teamId) {
                var transfers = _this.state.draftTransfers.map(function (transfer) { return ({
                    inId: transfer === null || transfer === void 0 ? void 0 : transfer.inId,
                    outId: transfer === null || transfer === void 0 ? void 0 : transfer.outId,
                }); });
                return Redux.Actions.Teams.submitTransfers(teamId, transfers);
            };
            _this.onTransfersReset = function (teamId) {
                return Redux.Actions.Teams.resetTransfers(teamId);
            };
            var getInitializedList = function (size) {
                var list = [];
                for (var item = 0; item < size; item++) {
                    list.push(null);
                }
                return list;
            };
            _this.state = {
                starting: getInitializedList(props.application.competition.lineupSize),
                bench: getInitializedList(props.application.competition.benchSize),
                budget: props.application.competition.budget,
                visibleWeekId: _this.props.matches.info.deadlineWeek,
                captainId: undefined,
                teamName: '',
                nameVisible: false,
                teamNameInitial: '',
                teamNameChanged: false,
                swapPlayerId: null,
                swappedFrom: null,
                initializedExternally: false,
                savingTeamPending: false,
                leagues: _this.props.user.leagues,
                deadlineWeekTransfers: [],
                pastTransfers: [],
                draftTransfers: [],
                teamPointsInfo: {
                    generalPoints: null,
                    generalRank: null,
                    visibleWeekPoints: null,
                    visibleWeekRank: null,
                },
                initialStarting: getInitializedList(props.application.competition.lineupSize),
                initialBench: getInitializedList(props.application.competition.benchSize),
                initialBudget: props.application.competition.budget,
            };
            return _this;
        }
        AbstractTeam.prototype.componentDidUpdate = function (prevProps) {
            if (this.props.matches.info.deadlineWeek !== prevProps.matches.info.deadlineWeek) {
                this.setState({ visibleWeekId: this.props.matches.info.deadlineWeek });
            }
            if (this.props.user.leagues.length !== prevProps.user.leagues.length) {
                this.setState({ leagues: this.props.user.leagues });
            }
        };
        AbstractTeam.prototype.render = function () {
            return (React.createElement(WrappedComponent, __assign({ setStarting: this.setStarting, setBench: this.setBench, setBudget: this.setBudget, setTeamName: this.setTeamName, setCaptainId: this.setCaptainId, isPickAble: this.isPickAble, onTeamSave: this.onTeamSave, onTeamNameChange: this.onTeamNameChange, onNameVisibleChange: this.onNameVisibleChange, onCaptainSelect: this.onCaptainSelect, removeBenchPlayer: this.removeBenchPlayer, removeStartingPlayer: this.removeStartingPlayer, pickPlayer: this.pickPlayer, starting: this.state.starting, bench: this.state.bench, captainId: this.state.captainId, teamName: this.state.teamName, budget: this.state.budget, leagues: this.state.leagues, savingTeamPending: this.state.savingTeamPending, swapPlayerId: this.state.swapPlayerId, swappedFrom: this.state.swappedFrom, initializedExternally: this.state.initializedExternally, visibleWeekId: this.state.visibleWeekId, teamNameChanged: this.state.teamNameChanged, teamPointsInfo: this.state.teamPointsInfo, draftTransfers: this.state.draftTransfers, deadlineWeekTransfers: this.state.deadlineWeekTransfers, pastTransfers: this.state.pastTransfers, nameVisible: this.state.nameVisible, initTeamState: this.initTeamState, resetTeamName: this.resetTeamName, onTeamNameUpdate: this.updateTeamName, onTeamEdit: this.onTeamEdit, onTeamSelectionsUpdate: this.onTeamSelectionsUpdate, onDayChange: this.onDayChange, onPlayerSwap: this.onPlayerSwap, loadAllMatches: this.loadAllMatches, onTransferPlayerOut: this.onTransferPlayerOut, onDraftTransfersClear: this.onDraftTransfersClear, onTransferPlayerIn: this.onTransferPlayerIn, onTransfersSubmit: this.onTransfersSubmit, onTransfersReset: this.onTransfersReset }, this.props)));
        };
        return AbstractTeam;
    }(Component));
    var mapDispatchToProps = {
        create: Redux.Actions.Teams.add,
        fetchMatches: Redux.Actions.Matches.fetchMatches,
    };
    function mapStateToProps(_a) {
        var user = _a.user, matches = _a.matches, application = _a.application, gameData = _a.gameData;
        return {
            matches: matches,
            application: application,
            clubs: {
                isFetching: gameData.isFetching,
                loaded: gameData.loaded,
                data: gameData.data.clubs
            },
            players: {
                isFetching: gameData.isFetching,
                loaded: gameData.loaded,
                data: gameData.data.players,
            },
            user: user,
        };
    }
    return connect(mapStateToProps, mapDispatchToProps)(AbstractTeam);
};
export default withAbstractTeam;
