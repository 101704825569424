/* eslint-disable react/jsx-no-target-blank */
import {
    AbstractTeam,
    Block,
    Calendar,
    Col,
    Helpers,
    MatchDaySelector,
    Models,
    PlayerType,
    Redux,
    Row,
    Stats,
    Substitutes,
    Team,
    Title,
    TitleType,
} from '@fanarena/shared';
// import teamBackground from '@fanarena/shared/assets/img/bg-cycling-shimano.png';
// import teamSponsor from '@fanarena/shared/assets/img/SSC_logo.jpg';
import { pick } from 'lodash';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export interface Props {
    match: any;
}

export interface State {
    notFound: boolean;
    navigateToLeagues: boolean;
    selectedLeagueId: number | null;
    pointsWeekId: number | undefined;
}

class TeamContainer extends Component<Models.AbstractTeamType & Props, State> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);

        this.state = {
            notFound: false,
            navigateToLeagues: false,
            selectedLeagueId: null,
            pointsWeekId: (this.props.visibleWeekId ? this.props.visibleWeekId-1 : this.props.matches.data.length-1),
        };
    }

    componentDidMount() {
        if (this.state.pointsWeekId) {
            this.getTeamInfo(this.state.pointsWeekId);
        }
    }

    componentDidUpdate(prevProps: Models.AbstractTeamType & Props, prevState: State) {
        // Compare if the state has changed
        if (this.state.pointsWeekId && prevState.pointsWeekId !== this.state.pointsWeekId && this.props.players.data.length !== 0) {
            this.getTeamInfo(this.state.pointsWeekId);
        }

        // Compare if the prop has changed
        if (prevProps.visibleWeekId !== this.props.visibleWeekId) {
            this.setState({ pointsWeekId: this.props.visibleWeekId }); // Update the state
        }

        if(prevProps.application.competition.competitionFeed !== this.props.application.competition.competitionFeed) {
            this.setState({ notFound: true });
        }
    }

    getTeamInfo = (pointsWeekId: number) => {
        const teamId = parseInt(this.props.match.params.id);
        const playerProps = [
            'id',
            'name',
            'short',
            'positionId',
            'clubId',
            'value',
            'active',
            'ban',
            'injury',
            'form',
            'forename',
            'surname',
            'stats',
        ];

        Promise.all([Redux.Actions.Teams.points(teamId, pointsWeekId)])
            .then(([result]) => {
                const weekStat = result.weekStat.find((stat: any) => stat.weekId === pointsWeekId);

                const teamPointsInfo = {
                    generalPoints: result.team.points !== null ? result.team.points : '-',
                    generalRank: result.team.rank !== null ? result.team.rank : '-',
                    visibleWeekPoints: (weekStat && weekStat.points) || '-',
                    visibleWeekRank: (weekStat && weekStat.rank) || '-',
                    name: result.team.name !== null ? result.team.name : 'Selectie'
                };

                const teamName = result.team.name;
                let captainId: any, starting, bench;

                if (pointsWeekId === 22) {
                    console.log("POINTS WEEK ID 22")
                    // captainId = result.players.find((player: any) => player.cap === 1).id;
                     starting = result.players
                        .sort((playerA: any, playerB: any) => { 
                            return playerB.cap - playerA.cap })
                        .filter((player: any) => player.pos === 1)
                        .map((selection: any) => {
                            const player:any = this.props.players.data.find((player: any) => player.id === selection.id);

                            return Object.assign(
                                {
                                    inStarting: true, points: player?.stats ? player.stats
                                        .filter((stat: any) => stat.weekId === pointsWeekId)
                                        .reduce(
                                            (acc: any, stat: any) => acc + stat.points,
                                            0,
                                        ) : 0
                                },
                                pick(player, playerProps)
                            );
                        });
                    bench = result.players
                        .filter((player: any) => player.pos === 0)
                        .map((selection: any) => {
                            const player:any = this.props.players.data.find((player: any) => player.id === selection.id);

                            return Object.assign(
                                {
                                    inStarting: false, points: player?.stats ? player.stats
                                        .filter((stat: any) => stat.weekId === pointsWeekId)
                                        .reduce(
                                            (acc: any, stat: any) => acc + stat.points,
                                            0,
                                        ) : 0
                                },
                                pick(player, playerProps)
                            );
                        });

                        console.log('starting',starting)
                } else {
                     captainId = result.players.find((player: any) => player.cap === 1).id;
                     starting = result.players
                        .sort((playerA: any, playerB: any) => { 
                            return playerB.cap - playerA.cap })
                        .filter((player: any) => player.pos === 1)
                        .map((selection: any) => {
                            const player:any = this.props.players.data.find((player: any) => player.id === selection.id);

                            return Object.assign(
                                {
                                    inStarting: true, points: player?.stats && player.stats
                                        .filter((stat: any) => stat.weekId === pointsWeekId)
                                        .reduce(
                                            (acc: any, stat: any) => acc + (stat.playerId === captainId && stat.value === '1') ? stat.points + 25 :
                                                (stat.playerId === captainId && stat.value === '2') ? stat.points + 20 :
                                                    (stat.playerId === captainId && stat.value === '3') ? stat.points + 15 :
                                                        (stat.playerId === captainId && stat.value === '4') ? stat.points + 10 :
                                                            (stat.playerId === captainId && stat.value === '5') ? stat.points + 5 : stat.points,
                                            0,
                                        )
                                },
                                pick(player, playerProps)
                            );
                            // Object.assign(
                            //     {
                            //         inStarting: true, points: player.stats
                            //             .filter((stat: any) => stat.weekId === pointsWeekId)
                            //             .reduce(
                            //                 (acc: any, stat: any) => acc + (stat.playerId === captainId && stat.value === '1') ? stat.points + 25 :
                            //                     (stat.playerId === captainId && stat.value === '2') ? stat.points + 20 :
                            //                         (stat.playerId === captainId && stat.value === '3') ? stat.points + 15 :
                            //                             (stat.playerId === captainId && stat.value === '4') ? stat.points + 10 :
                            //                                 (stat.playerId === captainId && stat.value === '5') ? stat.points + 5 : stat.points,
                            //                 0,
                            //             )
                            //     },
                            //     pick(player, playerProps)
                            // ),
                        });
                    bench = result.players
                        .filter((player: any) => player.pos === 0)
                        .map((selection: any) => {
                            const player:any = this.props.players.data.find((player: any) => player.id === selection.id);

                            return Object.assign(
                                {
                                    inStarting: false, points: player?.stats && player.stats
                                        .filter((stat: any) => stat.weekId === pointsWeekId)
                                        .reduce(
                                            (acc: any, stat: any) => acc + stat.points,
                                            0,
                                        )
                                },
                                pick(player, playerProps)
                            );
                            // Object.assign(
                            //     {
                            //         inStarting: true, points: player.stats
                            //             .filter((stat: any) => stat.weekId === pointsWeekId)
                                        // .reduce(
                                        //     (acc: any, stat: any) => acc + stat.points,
                                        //     0,
                                        // )
                            //     },
                            //     pick(player, playerProps)
                            // ),
                        });
                }

                const budget = result.players.reduce(
                    (acc: any, player: any) => acc - player.value,
                    this.props.application.competition.budget,
                );

                this.props.loadAllMatches();
                this.props.initTeamState(
                    starting,
                    bench,
                    teamName,
                    captainId,
                    budget,
                    undefined,
                    pointsWeekId,
                    teamPointsInfo,
                );
                
            })
            .catch(error => {
                console.log(error);
                this.setState({ notFound: true });
            });
        }

    render() {
        const {
            application,
            starting,
            bench,
            matches,
            visibleWeekId,
            initializedExternally,
            teamPointsInfo,
        } = this.props;
        const startingByPositions = Helpers.startingListToPositionsList(
            starting,
            application.competition.lineupPositionRows,
        );

        const deadlineWeek = matches.info && matches.info.deadlineWeek;
        const displayWeek = matches.info && matches.info.displayWeek;
        const match = matches.data.find(match => match.weekId === visibleWeekId);

        //const loggedInUserTeam = this.props.user && this.props.user.teams && this.props.user.teams[0];
        const showBench = application.competition.benchSize > 0;
            //true; //!deadlineWeek || (loggedInUserTeam && loggedInUserTeam.id === parseInt(this.props.match.params.id));
        const lastGameWeek = deadlineWeek ? deadlineWeek : displayWeek ? displayWeek + 1 : displayWeek;

        console.log('ini', initializedExternally , visibleWeekId , lastGameWeek);
        console.log(startingByPositions);
        return (
            <React.Fragment>
            {(this.state.notFound) && <Redirect to={{ pathname: `/home` }} />}
                {(initializedExternally && visibleWeekId && lastGameWeek && (
                    <Row gutter={[16, 16]} style={{marginBottom: '10px'}}>
                        <Col lg={24}>
                            <MatchDaySelector
                                day={visibleWeekId}
                                max={23}
                                min={1}
                                name={(match && match.feedUrl) || ''}
                                onNext={(e: any) => this.props.onDayChange('next')}
                                onPrev={(e: any) => this.props.onDayChange('prev')}
                            />
                        </Col>
                    </Row>
                )) ||
                    null}
                {(initializedExternally && (
                    <Row gutter={[16, 16]}>
                        <Col lg={12} md={13} sm={12} xs={24}>
                        <Title type={TitleType.h3}>Overzicht</Title>
                            <Block>
                                <Stats
                                    generalPoints={teamPointsInfo.generalPoints}
                                    generalRank={teamPointsInfo.generalRank}
                                    visibleWeekPoints={teamPointsInfo.visibleWeekPoints}
                                    visibleWeekRank={teamPointsInfo.visibleWeekRank}
                                />
                            </Block>
                            <Title type={TitleType.h3}>{teamPointsInfo.name}</Title>
                            <Block>
                                <Team
                                    widthRatio={12}
                                    heightRatio={12}
                                    bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg_giro24.jpg'}
                                    bgSponsor={''}
                                    selection={startingByPositions}
                                    assetsCdn={application.competition.assetsCdn}
                                    playerType={PlayerType.Cycling}
                                    playerBadgeColor="#000"
                                    playerBadgeBgColor="#fff"
                                    playerPointsColor="#FFF"
                                    playerPointsBgColor="#FF286E"
                                />
                                {(showBench && (
                                    <Substitutes
                                        selection={bench}
                                        title="De bus"
                                        bgColor="#3C3C3C"
                                        subTitle="Renners in de bus scoren geen punten"
                                        playerType={PlayerType.Cycling}
                                        assetsCdn={application.competition.assetsCdn}
                                        playerBadgeColor="#000"
                                        playerBadgeBgColor="#fff"
                                        playerPointsColor="#000"
                                        showStatsPoints={true}
                                        playerPointsBgColor="#fff"
                                    />
                                )) ||
                                    null}
                                {/* <Block style={{margin: 0, padding: "10px 0"}}><a href="https://lidl.be" target="_blank"><img alt="" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg" /></a></Block> */}
                                </Block>
                        </Col>
                        <Col lg={12} md={11} sm={12} xs={24}>
                            <Title type={TitleType.h3}>Kalender</Title>
                            <Block>
                                <Calendar
                                    data={matches.data}
                                    size={25}
                                    showHeader={false}
                                    isLoading={matches.isFetching}
                                />
                            </Block>
                            <div
                                className="RectangleLarge"
                                style={{ width: '300px', height: '250px', margin: '0px auto' }}
                            ></div>
                        </Col>
                    </Row>
                )) ||
                    null}
            </React.Fragment>
        );
    }
}

const WrappedComponent = AbstractTeam(TeamContainer);

export default WrappedComponent;
