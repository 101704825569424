import './App.css';

import { GlobalStyle, Header, Layout, Redux, Spinner, ThemeProvider } from '@fanarena/shared';
import lockr from 'lockr';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link,Redirect, Route, Switch, useLocation } from 'react-router-dom';

import VrtLogin from './components/VrtLogin';
import ChooseGame from './pages/ChooseGame';
import EditTeam from './pages/EditTeam';
import History from './pages/History';
import Home from './pages/Home';
import Join from './pages/Join';
import Leagues from './pages/Leagues';
import NewTeam from './pages/NewTeam';
import Points from './pages/Points';
import Prizes from './pages/Prizes';
import Rankings from './pages/Rankings';
import Rules from './pages/Rules';
import Stats from './pages/Stats';
import Team from './pages/Team';
import Transfers from './pages/Transfers';
import VrtCallback from './pages/VrtCallback';
import SporzaWielermanagerTheme from './styles/theme';

function loggedIn(): boolean {
    return !!lockr.get('token');
}

function NoMatch() {
    return (
        <div>
            <h3>
                Pagina niet gevonden, <Link to="/home" style={{ color: "#FF286E" }}>
                    terug naar home
                </Link>
            </h3>
        </div>
    );
}

const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route
        {...rest}
        render={props =>
            loggedIn() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
            )
        }
    />
);

function App(props: WithStateToProps) {
    return (
        <ThemeProvider theme={SporzaWielermanagerTheme}>
            <Router>
                <GlobalStyle />
                <Header renderVrtWidget={() => <VrtLogin id="VrtHeader" elemStyle={{ width: '9.3em' }} theme="c4" />} />
                <Spinner />
                <Layout>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/home" component={Home} />
                        <Route path="/rules" component={Rules} />
                        <Route path="/join/:code" component={Join} />
                        <Route path="/stats" component={Stats} />
                        <Route path="/prizes" component={Prizes} />
                        <Route path="/rankings" component={Rankings} />
                        <Route path="/provider/vrt" component={VrtCallback} />
                        <Route path="/public/:id" component={Points} />
                        {!props.application.competition.inactive && props.user.fetched && (
                            <>
                                <PrivateRoute path="/transfers/:id" component={Transfers} />
                                <PrivateRoute path="/team/:id" component={Team} />
                                <PrivateRoute path="/points/:id" component={Points} />
                                <PrivateRoute path="/edit/:id" component={EditTeam} />
                                <PrivateRoute path="/new" component={NewTeam} />
                                <PrivateRoute path="/game" component={ChooseGame} />
                                <PrivateRoute path="/leagues/:leagueId?" component={Leagues} />
                                <PrivateRoute path="/history" component={History} />
                            </>
                        )}
                        <Route path="*" component={NoMatch} />
                    </Switch>
                </Layout>
            </Router>
        </ThemeProvider>
    );
}

function mapStateToProps({ user, application }: Redux.StoreState) {
    return {
        user,
        application
    };
}
type WithStateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(App);
