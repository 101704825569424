var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Player, { PlayerType } from '../Player';
import { Sponsor, TeamStyle } from './style';
var Team = /** @class */ (function (_super) {
    __extends(Team, _super);
    function Team() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Team.prototype.render = function () {
        var _a = this.props, bg = _a.bg, bgSponsor = _a.bgSponsor, widthRatio = _a.widthRatio, heightRatio = _a.heightRatio, selection = _a.selection, playerType = _a.playerType, playerBadgeColor = _a.playerBadgeColor, playerBadgeBgColor = _a.playerBadgeBgColor, playerPointsColor = _a.playerPointsColor, playerPointsBgColor = _a.playerPointsBgColor, onRemove = _a.onRemove, onSwap = _a.onSwap, onPlaceholderClick = _a.onPlaceholderClick, swapPlayerId = _a.swapPlayerId, swappedFrom = _a.swappedFrom, assetsCdn = _a.assetsCdn, actionLessPlayerIds = _a.actionLessPlayerIds, showPlayerValue = _a.showPlayerValue;
        return (React.createElement(TeamStyle, { bg: bg, widthRatio: widthRatio, heightRatio: heightRatio },
            bgSponsor && React.createElement(Sponsor, { "aria-hidden": "true", bg: bgSponsor, widthRatio: widthRatio, heightRatio: heightRatio }), selection === null || selection === void 0 ? void 0 :
            selection.map(function (position, positionIndex) {
                var sportSpecificProps = {};
                return (React.createElement("div", { className: "position", key: "positionIndex-" + positionIndex }, position.map(function (player, playerIndex) {
                    if (playerType === PlayerType.Cycling && player) {
                        sportSpecificProps.shirtCycling = assetsCdn + "/jerseys/cycling/" + player.clubId + ".png";
                    }
                    return (React.createElement(Player, __assign({ key: "playerIndex-" + playerIndex, pointsColor: playerPointsColor, pointsBgColor: playerPointsBgColor, badgeColor: playerBadgeColor, badgeBgColor: playerBadgeBgColor, player: player, showPlayerValue: showPlayerValue, type: playerType, onRemove: onRemove, onSwap: onSwap, onPlaceholderClick: onPlaceholderClick, swapPlayerId: swapPlayerId, actionLessPlayerIds: actionLessPlayerIds, swappedFrom: swappedFrom }, sportSpecificProps)));
                })));
            })));
    };
    return Team;
}(Component));
export default Team;
