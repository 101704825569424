var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import moment from 'moment';
import React, { Component } from 'react';
import Select from '../../components/UI/Select';
import Link from '../UI/Link';
import { PlayerListContainerStyle, SelectGroupStyle, TableStyle } from './../PlayerList/style';
var Calendar = /** @class */ (function (_super) {
    __extends(Calendar, _super);
    function Calendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            filters: {
                month: -1,
            },
        };
        _this.onFilterChange = function (name, value) {
            var _a;
            var filters = Object.assign({}, _this.state.filters, (_a = {}, _a[name] = value, _a));
            _this.setState({ filters: filters });
        };
        _this.matchFilter = function (match, filters) {
            var show = true;
            if (filters.month !== -1 && filters.month !== moment(match.date).month()) {
                show = false;
            }
            return show;
        };
        return _this;
    }
    Calendar.prototype.render = function () {
        var _this = this;
        var _a = this.props, data = _a.data, size = _a.size, showHeader = _a.showHeader, isLoading = _a.isLoading, showMonthsFilter = _a.showMonthsFilter;
        var matchesList = data.filter(function (match) { return _this.matchFilter(match, _this.state.filters); });
        var months = [
            { id: -1, name: 'Alle maanden' },
            { id: 0, name: 'Januari' },
            { id: 1, name: 'Februari' },
            { id: 2, name: 'Maart' },
            { id: 3, name: 'April' },
            { id: 4, name: 'Mei' },
            { id: 5, name: 'Juni' },
            { id: 6, name: 'Juli' },
            { id: 7, name: 'Augustus' },
            { id: 8, name: 'September' },
            { id: 9, name: 'Oktober' },
            { id: 10, name: 'November' },
            { id: 11, name: 'December' },
        ];
        var columns = [
            {
                title: '',
                key: 'feedUrl',
                dataIndex: 'feedUrl',
                width: '75%',
                render: function (text, record) {
                    return (React.createElement(Link, { href: record.info, type: 'external' }, record.weekId + ". " + text));
                },
            },
            {
                key: 'date',
                title: '',
                width: '25%',
                dataIndex: 'date',
                render: function (text) {
                    return React.createElement("span", { style: { float: 'right' } },
                        React.createElement("b", null, moment(text).format('DD/MM [om] H:mm')));
                },
            },
        ];
        return (React.createElement(PlayerListContainerStyle, null,
            (showMonthsFilter && (React.createElement(SelectGroupStyle, null, (React.createElement(Select, { block: true, keyProperty: 'id', textProperty: 'name', onSelect: function (value) { return _this.onFilterChange('month', value); }, values: months, placeholder: months[0].name })) || null))) ||
                null,
            React.createElement(TableStyle, { columns: columns, dataSource: matchesList, showHeader: showHeader, locale: { emptyText: 'No matches found.' }, loading: isLoading, pagination: matchesList.length > size ? { pageSize: size } : false, rowKey: "id", rowClassName: function (_record, index) {
                    return index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even';
                } })));
    };
    return Calendar;
}(Component));
export default Calendar;
