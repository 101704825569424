/* eslint-disable react/jsx-no-target-blank */
import {
    AbstractTeam,
    Block,
    Button,
    Col,
    Helpers,
    Models,
    PlayerList,
    PlayerType,
    Redux,
    Row,
    Substitutes,
    Team,
    TeamOverview,
    Title,
    TitleType,
    TransfersList,
} from '@fanarena/shared';
// import teamBackground from '@fanarena/shared/assets/img/bg-cycling-shimano.png';
// import teamSponsor from '@fanarena/shared/assets/img/SSC_logo.jpg';
import { Icon, Modal } from 'antd';
import { pick } from 'lodash';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
const { confirm } = Modal;

export interface Props {
    match: any;
}

export interface State {
    notFound: boolean;
    performingTransfer: boolean;
    performingTransferReset: boolean;
}

class TransfersContainer extends Component<Models.AbstractTeamType & Props, State> {
    constructor(props: Models.AbstractTeamType & Props) {
        super(props);

        this.state = {
            notFound: false,
            performingTransfer: false,
            performingTransferReset: false,
        };
    }

    onPlaceHolderClick = (e: any) => {
        scroller.scrollTo('all-players', {
            duration: 1000,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    };

    componentDidMount() {
        if (this.props.visibleWeekId) {
            this.loadTeam();
        }
    }

    componentDidUpdate(prevProps: Models.AbstractTeamType & Props) {
        if (this.props.visibleWeekId && this.props.visibleWeekId !== prevProps.visibleWeekId) {
            this.loadTeam();
        }

        if(prevProps.application.competition.competitionFeed !== this.props.application.competition.competitionFeed) {
            this.setState({ notFound: true });
        }
    }

    loadTeam = () => {
        const teamId = parseInt(this.props.match.params.id);

        const urlTeam = this.props.user.teams.find(t => {
            return t.id === teamId
        });
        if(!urlTeam) { this.setState({ notFound: true }); }
        
        const playerProps = [
            'id',
            'name',
            'short',
            'positionId',
            'clubId',
            'value',
            'ban',
            'injury',
            'form',
            'forename',
            'surname',
        ];

        const selectionProps: any[] = [];

        return Redux.Actions.Teams.get(teamId)
            .then(result => {
                const starting = result.players
                    .filter((player: any) => player.selection.positionId === 1)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: true },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps),
                        ),
                    );
                const bench = result.players
                    .filter((player: any) => player.selection.positionId === 0)
                    .map((player: any) =>
                        Object.assign(
                            { inStarting: false },
                            pick(player, playerProps),
                            pick(player.selection, selectionProps),
                        ),
                    );
                const teamName = result.team.name;

                const captainId = result.players.find((player: any) => player.selection.captain === 1).id;

                const pastTransfers = !result.transfers
                    ? ([] as Models.Transfer[])
                    : result.transfers
                        .filter(
                            (transfer: any) =>
                                this.props.matches.info.deadlineWeek &&
                                transfer.weekId < this.props.matches.info.deadlineWeek,
                        )
                        .map((transfer: any) => ({
                            inId: transfer.inId,
                            outId: transfer.outId,
                            weekId: transfer.weekId,
                        }));

                const deadlineWeekTransfers = !result.transfers
                    ? ([] as Models.Transfer[])
                    : result.transfers
                        .filter((transfer: any) => transfer.weekId === this.props.matches.info.deadlineWeek)
                        .map((transfer: any) => ({
                            inId: transfer.inId,
                            outId: transfer.outId,
                            weekId: transfer.weekId,
                        }));

                const getPlayersValuesWithTransfers = (players: any) => {
                    const playersValue = players.reduce((acc: any, player: any) => {
                        const wasTransferred = result.transfers.find((transfer: any) => transfer.inId === player.id);

                        const playerValue = wasTransferred
                            ? Helpers.roundNextHalf(
                                player.value +
                                (player.value * (this.props.application.competition.transferTaxPercentage || 0)) /
                                100,
                            )
                            : player.value;

                        return acc + playerValue;
                    }, 0);
                    return this.props.application.competition.budget - playersValue;
                };

                const budget =
                    result.team.budget !== null ? result.team.budget : getPlayersValuesWithTransfers(result.players);
                this.props.initTeamState(
                    starting,
                    bench,
                    teamName,
                    captainId,
                    budget,
                    undefined,
                    undefined,
                    undefined,
                    result.transfers,
                    deadlineWeekTransfers,
                    pastTransfers,
                );
            })
            .catch(error => {
                this.setState({ notFound: true });
            });
    };

    formatTransfers = (transfer: any) => {
        return {
            inPlayer: this.props.players.data.find((player: any) => player.id === transfer.inId),
            outPlayer: this.props.players.data.find((player: any) => player.id === transfer.outId),
            weekId: transfer && transfer.weekId,
        };
    };

    onLineupPlayerOut = (player: Models.Player) => {
        this.props.removeStartingPlayer(player);
        this.props.onTransferPlayerOut(player);
    };

    onBenchPlayerOut = (player: Models.Player) => {
        this.props.removeBenchPlayer(player);
        this.props.onTransferPlayerOut(player);
    };

    onPlayerIn = (player: Models.Player) => {
        this.props.pickPlayer(player, true);
        this.props.onTransferPlayerIn(player);
    };

    submitTransfers = (teamId: number) => {
        this.setState({ performingTransfer: true });
        this.props
            .onTransfersSubmit(teamId)
            .then(() => this.loadTeam())
            .then(() => {
                this.setState({ performingTransfer: false });
            });
    };

    showConfirm = (submitTransfers: any, teamId: number) => {
        confirm({
          title: 'OPGELET: Ben je héél zeker dat je deze transfer(s) wilt doen?',
          content: 'Transfers kunnen NIET (!) ongedaan worden gemaakt. Transfers zijn definitief! Denk heel goed na en druk op “OK” als je ze wilt doorvoeren. Twijfel je nog? Druk dan op “Annuleer”.',
          cancelText: 'Annuleer',
          onOk() {
            submitTransfers(teamId);
          },
        });
      }
    
    resetTransfers = (teamId: number) => {
        this.setState({ performingTransferReset: true });
        this.props
            .onTransfersReset(teamId)
            .then(() => this.loadTeam())
            .then(() => {
                this.setState({ performingTransferReset: false });
            });
    };

    render() {
        const {
            application,
            starting,
            bench,
            budget,
            matches,
            user,
            initializedExternally,
            deadlineWeekTransfers,
            pastTransfers,
            draftTransfers,
            players,
        } = this.props;
        const startingByPositions = Helpers.startingListToPositionsList(
            starting,
            application.competition.lineupPositionRows,
        );
        const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
        const startingPicked = starting.filter((player: any) => !!player);
        const benchPicked = bench.filter((player: any) => !!player);
        const totalPlayersPicked = startingPicked.length + benchPicked.length;
        const team = this.props.user && this.props.user.teams && this.props.user.teams[0];

        const firstPlayingWeekPassed =
            team &&
            team.id &&
            matches &&
            matches.info &&
            matches.info.deadlineWeek &&
            matches.info.deadlineWeek > team.weekId;
        const notTeamOwner = team && team.userId && user.data && team.userId !== user.data.id;

        const pastTransfersFormatted = pastTransfers.map(this.formatTransfers);

        const deadlineWeekTransfersFormatted = deadlineWeekTransfers.concat(draftTransfers).map(this.formatTransfers);

        const canSaveDraftTransfers =
            draftTransfers.filter((draftTransfer: any) => !!draftTransfer.inId && !!draftTransfer.outId).length ===
            draftTransfers.length;

        const remainingTransfers =
            application.competition.transfersAllowed -
            (deadlineWeekTransfersFormatted.length + pastTransfersFormatted.length);

        const canTransferOut =
            remainingTransfers > 0 && deadlineWeekTransfersFormatted.length < application.competition.transfersAllowed;

        const draftPlayerInIds = draftTransfers
            .map((transfer: any) => transfer.inId)
            .filter((inId: any) => inId !== null);

        return (
            <React.Fragment>
                {(notTeamOwner || this.state.notFound) && <Redirect to={{ pathname: `/home` }} />}
                {team && !firstPlayingWeekPassed && <Redirect to={{ pathname: `/edit/${team.id}` }} />}
                {(initializedExternally && (
                    <Row gutter={[16, 16]}>
                        <Col md={12} sm={12} xs={24}>
                            

                            <Title type={TitleType.h3}>Selectie</Title>
                                <Block>
                                
                                <TeamOverview
                                    budget={budget}
                                    totalPlayers={totalPlayersToPick}
                                    showRemainingTransfers={true}
                                    remainingTransfers={remainingTransfers}
                                    totalPlayersSelected={totalPlayersPicked}
                                    teamSameClubPlayersLimit={this.props.application.competition.teamSameClubPlayersLimit || 4}
                                />
                                <Team
                                    widthRatio={12}
                                    heightRatio={12}
                                    bg={'https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/bg_giro24.jpg'}
                                    bgSponsor={''}
                                    selection={startingByPositions}
                                    assetsCdn={application.competition.assetsCdn}
                                    playerType={PlayerType.Cycling}
                                    playerBadgeColor="#000"
                                    playerBadgeBgColor="#fff"
                                    showPlayerValue={true}
                                    onRemove={canTransferOut && this.onLineupPlayerOut}
                                    actionLessPlayerIds={draftPlayerInIds}
                                    onPlaceholderClick={this.onPlaceHolderClick}
                                    playerPointsColor="#FFF"
                                    playerPointsBgColor="#FF286E"
                                />
                                {(this.props.application.competition.benchSize > 0 && <Substitutes
                                    selection={bench}
                                    title="De bus"
                                    bgColor="#3C3C3C"
                                    playerType={PlayerType.Cycling}
                                    assetsCdn={application.competition.assetsCdn}
                                    onPlaceholderClick={this.onPlaceHolderClick}
                                    showPlayerValue={true}
                                    playerBadgeColor="#000"
                                    playerBadgeBgColor="#fff"
                                    playerPointsColor="#000"
                                    playerPointsBgColor="#fff"
                                    onRemove={canTransferOut && this.onBenchPlayerOut}
                                    actionLessPlayerIds={draftPlayerInIds}
                                />)}
                                {/* <Block style={{margin: 0, padding: "10px 0"}}><a href="https://lidl.be" target="_blank"><img alt="" src="https://sporza-fantasy-manager.s3.eu-west-3.amazonaws.com/files/banner_lidl.jpg" /></a></Block> */}
                            </Block>

                        </Col>
                        <Col md={12} sm={12} xs={24}>
                        <Title type={TitleType.h3}>Transfers</Title>
                            <Block>
                                {(pastTransfersFormatted && pastTransfersFormatted.length && (
                                    <React.Fragment>
                                        <div style={{ textAlign: 'center' }}>
                                            <Title type={TitleType.h4}>History</Title>
                                        </div>

                                        <TransfersList
                                            data={pastTransfersFormatted}
                                            showHeader={true}
                                            showWeek={true}
                                            size={5}
                                        />
                                    </React.Fragment>
                                )) ||
                                    null}

                                <React.Fragment>
                                    <div style={{ textAlign: 'center' }}>
                                        <Title
                                            type={TitleType.h4}
                                        >{`Rit ${matches.info.deadlineWeek} transfers`}</Title>
                                    </div>

                                    <TransfersList
                                        data={deadlineWeekTransfersFormatted}
                                        showHeader={true}
                                        tax={application.competition.transferTaxPercentage}
                                        size={5}
                                    />

                                    <div style={{ textAlign: 'center', paddingTop: '5px' }}>
                                        {(draftTransfers && draftTransfers.length && canSaveDraftTransfers && (
                                            <span style={{ padding: '5px' }}>
                                                <Button
                                                    onClick={(e: any) => this.showConfirm(this.submitTransfers, team.id)}
                                                    disabled={this.state.performingTransfer}
                                                    loading={this.state.performingTransfer}
                                                    size="default"
                                                >
                                                    {(!this.state.performingTransfer && (
                                                        <Icon type="save" theme="filled" />
                                                    )) ||
                                                        null}
                                                    Bevestig
                                                </Button>
                                            </span>
                                        )) ||
                                            null}

                                        {(draftTransfers && draftTransfers.length && (
                                            <span style={{ padding: '5px' }}>
                                                <Button
                                                    onClick={(e: any) => this.props.onDraftTransfersClear()}
                                                    size="default"
                                                >
                                                    <Icon type="close-circle" theme="filled" />
                                                    Wis
                                                </Button>
                                            </span>
                                        )) ||
                                            null}

                                        {/* {(deadlineWeekTransfers && deadlineWeekTransfers.length && (
                                            <span style={{ padding: '5px' }}>
                                                <Button
                                                    disabled={this.state.performingTransferReset}
                                                    loading={this.state.performingTransferReset}
                                                    onClick={(e: any) => this.resetTransfers(team.id)}
                                                    size="default"
                                                >
                                                    {(!this.state.performingTransferReset && (
                                                        <Icon type="delete" theme="filled" />
                                                    )) ||
                                                        null}
                                                    Wis
                                                </Button>
                                            </span>
                                        )) ||
                                            null} */}
                                    </div>
                                </React.Fragment>
                            </Block>
                        <Title type={TitleType.h3}>Alle Renners</Title>
                            <Block>
                                <Element name="all-players">
                                    
                                    <PlayerList
                                        clubs={this.props.clubs.data}
                                        isLoading={this.props.players.isFetching}
                                        hidePositions={true}
                                        assetsCdn={application.competition.assetsCdn}
                                        isPickAble={(player: Models.Player) =>
                                            this.props.isPickAble(player, true, true)
                                        }
                                        playerType={PlayerType.Cycling}
                                        actionLabel={'Transfer'}
                                        data={players.data.filter((player) => player.active === 1)}
                                        playerTax={application.competition.transferTaxPercentage}
                                        onPick={this.onPlayerIn}
                                        action
                                        showHeader={false}
                                        size={10}
                                    />
                                </Element>
                            </Block>
                            <div
                                className="RectangleLarge"
                                style={{ width: '300px', height: '250px', margin: '0px auto' }}
                            ></div>
                        </Col>
                    </Row>
                )) ||
                    null}
            </React.Fragment>
        );
    }
}

const WrappedComponent = AbstractTeam(TransfersContainer);

export default WrappedComponent;
