var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { H1Style, H2Style, H3Style, H4Style } from './style';
export var TitleType;
(function (TitleType) {
    TitleType["h1"] = "h1";
    TitleType["h2"] = "h2";
    TitleType["h3"] = "h3";
    TitleType["h4"] = "h4";
})(TitleType || (TitleType = {}));
var Title = /** @class */ (function (_super) {
    __extends(Title, _super);
    function Title() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Title.prototype.render = function () {
        var _a = this.props, type = _a.type, children = _a.children;
        return (React.createElement(React.Fragment, null,
            type === TitleType.h1 && React.createElement(H1Style, null, children),
            type === TitleType.h2 && React.createElement(H2Style, null, children),
            type === TitleType.h3 && React.createElement(H3Style, null, children),
            type === TitleType.h4 && React.createElement(H4Style, null, children)));
    };
    return Title;
}(Component));
export default Title;
