var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '../../styles/styled';
var Overview = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    // align-items: center;\n    // padding-top: 15px;\n    // padding-bottom: 15px;\n"], ["\n    // align-items: center;\n    // padding-top: 15px;\n    // padding-bottom: 15px;\n"])));
var Value = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 1.5em;\n    font-weight: bold;\n"], ["\n    font-size: 1.5em;\n    font-weight: bold;\n"])));
var Label = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 1.2em;\n"], ["\n    font-size: 1.2em;\n"])));
export { Overview, Value, Label };
var templateObject_1, templateObject_2, templateObject_3;
